import React, { useState, useEffect } from 'react';
import LightBox from 'components/LightBox';

const PhotoModal = (props) => {
    const { src, width, height, style } = props;
    const [ toggle, setToggle ] = useState(false);
    const [ previewImage, setPreviewImage ] = useState("");

    useEffect(() => {
        if(toggle) setPreviewImage(src);
    }, [src, toggle])
    
    const handleLightboxCallback = value => {
        setToggle(value);
        setTimeout(() => {
            setPreviewImage("");
        }, 200)
    }

    return (<>
        <img
            src={src}
            width={width}
            height={height}
            onClick={() => setToggle(true)}
            style={{...style, cursor: 'pointer'}}
        />
        <LightBox open={toggle} cross={true} width="600px" callback={({open}) => handleLightboxCallback(open)}>
            <div className="light-box-body bg-white">
                <img src={previewImage} className="w-100 d-block" style={{verticalAlign: "top"}} />
            </div>
        </LightBox>
    </>)
}

export default PhotoModal;
