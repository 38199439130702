import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const CustomEditor = ({ data, callback }) => {
    const [ editor, setEditor ] = useState(null);

    return (
        <div className="ck-editor">
            <div id="toolbar-container"></div>
            <CKEditor
                editor={ DecoupledEditor }
                data={data}
                onReady={ editor => {
                    editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                    );
                    setEditor(editor);
                } }
                onChange={ ( event, editor ) => {
                    const newData = editor.getData();
                    callback(newData);
                } }
                config={{
                    ckfinder: {
                        uploadUrl: process.env.REACT_APP_API_URL+'/upload',
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('access_token')}`
                        },
                    },
                } }
            />
        </div>
    )
}



export default CustomEditor;