import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Upload from "components/Upload";
import { GlobalContext } from 'store/context/GlobalContext';
import { FormGroup, InputGroup } from 'components/Form/FormGroup';
import ConfirmButton from 'components/ConfirmButton';
import { getProductItem, updateProduct, getCategorySearch, getBrandsSearch } from 'services/authService';
import Switch from '@mui/material/Switch';
import useKeyboard from 'components/useKeyboard';

export default function Edit() {
    let mounted = true;
    const { id } = useParams();
    const { handleKeyDown } = useKeyboard();
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const { handleSubmit, formState: { isDirty, isValid, errors }, register, reset, getValues, setValue } = useForm({ mode: 'onChange', shouldUnregister: true });
    const [ switchToggle1, setSwitchToggle1 ] = useState(false);
    const [ switchToggle2, setSwitchToggle2 ] = useState(false);
    const [ imageClear, setImageClear ] = useState(false);
    const [ categoryList, setCategoryList ] = useState([]);
    const [ brand, setBrand ] = useState([]);
    const [ brandList, setBrandList ] = useState([]);
    const [ data, setData ] = useState({});
    const [ originalImage, setOriginalImage ] = useState("");

    const fetchData = useCallback(async() => {
        let result = await getProductItem(id);
        if(mounted){
            reset({
                sku: result.data.sku ? result.data.sku : "",
                name: result.data.name ? result.data.name : "",
                zh_name: result.data.zh_name ? result.data.zh_name : "",
                slug: result.data.slug ? result.data.slug : "",
                purchase_price: result.data.purchase_price && result.data.purchase_price.toString(),
                price: result.data.price && result.data.price.toString(),
                stock: result.data.stock && result.data.stock.toString(),
                reserve_stock: result.data.reserve_stock && result.data.reserve_stock.toString(),
                safety_limit: result.data.safety_limit && result.data.safety_limit.toString(),
                limit: result.data.limit && result.data.limit.toString(),
                weight: result.data.weight && result.data.weight.toString(),
                dimension: result.data.dimension && result.data.dimension.toString(),
                description: result.data.description ? result.data.description : "",
                category_id: result.data.category_id ? result.data.category_id : "",
                order: result.data.order && result.data.order.toString(),
            })
            setSwitchToggle1(result.data.status);
            setSwitchToggle2(!result.data.show);
            setData(result.data);
            setOriginalImage(result.data.image);
            let brandArray = [];
            result.data.brands.map((item) => {
                brandArray.push(item.id);
            })
            setBrand(brandArray);
        }
    }, [])

    const fetchBrandList = useCallback(async() => {
        let result = await getBrandsSearch();
        if(mounted){
            setBrandList(result.data);
        }
    }, [mounted])

    const fetchCategoryList = useCallback(async() => {
        let result = await getCategorySearch();
        if(mounted){
            setCategoryList(result.data);
        }
    }, [mounted])

    useEffect(() => {
        if(categoryList.length > 0) fetchData();
    }, [fetchData, categoryList])

    useEffect(() => {
        fetchCategoryList();
        fetchBrandList();
        return () => mounted = false;
    }, [fetchCategoryList, fetchBrandList])

    const onSubmit = () => {
        let values = getValues();
        
        let result = {...values, status: switchToggle1 ? "1" : "0", show: switchToggle2 ? "0" : "1", original_image: originalImage};

        const formData = new FormData();
		Object.keys(result).map(function(key, index) {
            if(key === 'image') return formData.append(key, result.image);
			return formData.append(key, result[key]);
        });

        brand.map((key) => {
            formData.append("brands[]", key);
        })

        setLoader(true);
        updateProduct(id, formData).then(res => {
            setSnack({open: true, text: res.messages});
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, theme: "danger", text: err.messages.error});
            setLoader(false);
        })
    }

    const handleValidate = () => {
        let values = getValues();
        if(values.order === ""){
            setSnack({open: true, text: "請輸入分類排序"});
            return false;
        }
        if(values.sku === ""){
            setSnack({open: true, text: "請輸入商品編號"});
            return false;
        }
        if(values.name === ""){
            setSnack({open: true, text: "請輸入商品英文名稱"});
            return false;
        }
        if(values.zh_name === ""){
            setSnack({open: true, text: "請輸入商品中文名稱"});
            return false;
        }
        if(values.slug === ""){
            setSnack({open: true, text: "請輸入URL slug"});
            return false;
        }
        if(values.purchase_price === ""){
            setSnack({open: true, text: "請輸入進貨價格"});
            return false;
        }
        if(values.price === ""){
            setSnack({open: true, text: "請輸入售價"});
            return false;
        }
        if(values.stock === ""){
            setSnack({open: true, text: "請輸入庫存數量"});
            return false;
        }
        if(values.reserve_stock === ""){
            setSnack({open: true, text: "請輸入後備庫存數量"});
            return false;
        }
        if(values.safety_limit === ""){
            setSnack({open: true, text: "請輸入庫存最低安全值"});
            return false;
        }
        if(values.safety_limit > 0 && values.limit < 1){
            setSnack({open: true, text: "限購數量最少為1"});
            return false;
        }
        if(values.limit === ""){
            setSnack({open: true, text: "請輸入限購數量"});
            return false;
        }
        if(values.limit > 0 && values.safety_limit < 1){
            setSnack({open: true, text: "庫存最低安全值最少為1"});
            return false;
        }
        if(values.weight === ""){
            setSnack({open: true, text: "請輸入重量"});
            return false;
        }
        if(values.dimension === ""){
            setSnack({open: true, text: "請輸入材積"});
            return false;
        }
        if(values.description === ""){
            setSnack({open: true, text: "請輸入商品簡介"});
            return false;
        }
        if(values.category_id === ""){
            setSnack({open: true, text: "請選擇商品分類"});
            return false;
        }

        return true;
    }

    const handleSelect = (status) => {
        let result = [];
        if(status === "1"){
            brandList.map((item) => result.push(item.id));
        }
        setBrand(result);
    }

    const handleTree = (array) => {
        return array.map((item, index) =>
            item.children ? (
                <optgroup label={item.name} key={index}>
                    {handleTree(item.children)}
                </optgroup>
            ) : (
                <option value={item.id} key={index}>{item.name}</option>
            )
        )
    }

    return (
        <div className="pdx-3">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="上下架狀態">
                        <div className="switch-group">
                            <Switch checked={switchToggle1} onChange={() => setSwitchToggle1(!switchToggle1)}/>
                            <span className="ml-2 font-size-14">{switchToggle1 ? "上架" : "下架"}</span>
                        </div>
                    </FormGroup>
                    <FormGroup label="商品隱藏">
                        <div className="switch-group">
                            <Switch checked={switchToggle2} onChange={() => setSwitchToggle2(!switchToggle2)}/>
                            <span className="ml-2 font-size-14">{switchToggle2 ? "隱藏" : "顯示"}</span>
                        </div>
                    </FormGroup>
                    <FormGroup label="排序" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="order"
                                type="number"
                                inputMode="numeric"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入分類排序"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => handleKeyDown(e, { number: true })}
                                {...register("order")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="商品編號 (SKU)" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="sku"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入商品編號"
                                {...register("sku")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="商品英文名稱" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="name"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入商品英文名稱"
                                {...register("name")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="商品中文名稱" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="zh_name"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入商品中文名稱"
                                {...register("zh_name")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="商品圖片">
                        <Upload
                            id="image"
                            maxWidth="auto"
                            maxHeight="200px"
                            name="image"
                            valid={{type: ["image/jpeg", "image/png"]}}
                            clearCallback={() => {
                                setValue("image", "");
                                setOriginalImage("");
                            }}
                            clear={imageClear}
                            callback={(value) => setValue("image", value.raw)}
                            defaultValue={data.image}
                            register={register}
                        />
                    </FormGroup>
                    <FormGroup label="URL slug" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="slug"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入URL slug"
                                {...register("slug")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="進貨價格" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="purchase_price"
                                type="number"
                                inputMode="decimal"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入商品進貨價格"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => handleKeyDown(e, { number: true, float: true })}
                                {...register("purchase_price")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="售價" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="price"
                                type="number"
                                inputMode="decimal"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入商品價格"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => handleKeyDown(e, { number: true, float: true })}
                                {...register("price")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="庫存數量" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="stock"
                                type="number"
                                inputMode="numeric"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入商品庫存數量"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => handleKeyDown(e, { number: true })}
                                {...register("stock")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="後備庫存數量" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="reserve_stock"
                                type="number"
                                inputMode="numeric"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入後備庫存數量"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => handleKeyDown(e, { number: true })}
                                {...register("reserve_stock")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="庫存最低安全值" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="safety_limit"
                                type="number"
                                inputMode="numeric"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入庫存最低安全值"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => handleKeyDown(e, { number: true })}
                                {...register("safety_limit")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="每筆訂單限購數量" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="limit"
                                type="number"
                                inputMode="numeric"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入每筆訂單限購數量"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => handleKeyDown(e, { number: true })}
                                {...register("limit")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="重量 (lbs)" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="weight"
                                type="number"
                                inputMode="decimal"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入商品重量"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => handleKeyDown(e, { number: true, float: true })}
                                {...register("weight")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="材積 (Cu in)" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="dimension"
                                type="number"
                                inputMode="decimal"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入商品材積"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => handleKeyDown(e, { number: true, float: true })}
                                {...register("dimension")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="商品簡介" required>
                        <InputGroup>
                            <textarea
                                tabIndex="1"
                                name="description"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入商品簡介"
                                rows="3"
                                {...register("description")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="商品分類" required>
                        <select
                            name="category_id"
                            defaultValue=""
                            {...register("category_id")}
                        >
                            <option value="" disabled>請選擇分類</option>
                            { handleTree(categoryList) }
                        </select>
                    </FormGroup>
                    <FormGroup label="適用品牌" required>
                        { brandList.map((item, index) => <SwitchGroup item={item} key={index} brand={brand} setBrand={setBrand} />) }
                        <div className="mgt-1">
                            <button type="button" className="common-btn table-btn black my-2" onClick={() => handleSelect("1")}>全選</button>
                            <button type="button" className="common-btn table-btn black my-2 ml-3" onClick={() => handleSelect("0")}>取消全選</button>
                        </div>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <ConfirmButton
                        className="submit-btn"
                        onValidate={handleValidate}
                        onSuccess={onSubmit}
                        confirmText="確定要送出嗎？"
                        confirm={{show: true, text: '送出'}}
                        cancel={{show: true}}
                    />
                </div>
            </form>
        </div>
    )
}

const SwitchGroup = (props) => {
    const { item, brand, setBrand } = props;
    const [ toggle, setToggle ] = useState(false);

    useEffect(() => {
        let index = brand.findIndex(e => e === item.id);
        setToggle(index !== -1 ? true : false);
    }, [brand])
 
    const handleChange = () => {
        let result = Array.from(brand);
        setToggle(!toggle);
        let index = result.findIndex(e => e === item.id);

        if(index !== -1){
            result.splice(index, 1);
        }else{
            result.push(item.id);
        }
        setBrand(result);
    }
    
    return (
        <div className="switch-group">
            <Switch checked={toggle} onChange={() => handleChange()} />
            <span className="ml-2 font-size-14">{item.name}</span>
        </div>
    )
}