import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { GlobalContext } from 'store/context/GlobalContext';
import { FormGroup, InputGroup, SwitchGroup } from 'components/Form/FormGroup';
import { updateUser, getBrandsSearch, getUserItem } from 'services/authService';
import ConfirmButton from 'components/ConfirmButton';
import PasswordShowButton from 'components/Form/PasswordShowButton';
import Switch from '@mui/material/Switch';
import useKeyboard from 'components/useKeyboard';

export default function Edit() {
    let mounted = true;
    const { handleKeyDown } = useKeyboard();
    const { id } = useParams();
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const [ passwordShow, setPasswordShow ] = useState(false);
    const [ repasswordShow, setRepasswordShow ] = useState(false);
    const { handleSubmit, formState: { isDirty, isValid, errors }, register, reset, getValues } = useForm({ mode: 'onChange', shouldUnregister: true });
    const [ brand, setBrand ] = useState([]);
    const [ brandList, setBrandList ] = useState([]);
    const [ data, setData ] = useState({});
    const [ switchToggle, setSwitchToggle ] = useState(false);

    const fetchData = useCallback(async() => {
        let result = await getUserItem(id);
        if(mounted){
            setData(result.data);
            setSwitchToggle(result.data.status);
            reset({
                username: result.data.username ? result.data.username : "",
                name: result.data.name ? result.data.name : "",
                postal_code: result.data.postal_code ? result.data.postal_code : "",
                country: result.data.country ? result.data.country : "",
                province: result.data.province ? result.data.province : "",
                city: result.data.city ? result.data.city : "",
                address1: result.data.address1 ? result.data.address1 : "",
                address2: result.data.address2 ? result.data.address2 : "",
                phone: result.data.phone ? result.data.phone : "",
                email: result.data.email ? result.data.email : "",
                delivery_fee: result.data.delivery_fee && parseFloat(result.data.delivery_fee).toString(),
            });
            let brandArray = [];
            result.data.brands.map((item) => {
                brandArray.push(item.id);
            })
            setBrand(brandArray);
        }
    }, [mounted])

    const fetchBrandList = useCallback(async() => {
        let result = await getBrandsSearch();
        if(mounted){
            setBrandList(result.data);
        }
    }, [mounted])

    useEffect(() => {
        fetchData();
        fetchBrandList();
        return () => mounted = false;
    }, [fetchData, fetchBrandList])

    const onSubmit = () => {
        let values = getValues();

        setLoader(true);
        updateUser(id, {...values, brands: brand, status: switchToggle ? "1" : "0"}).then(res => {
            setSnack({open: true, text: res.messages});
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const handleValidate = () => {
        let values = getValues();
        if(values.username === ""){
            setSnack({open: true, text: "請輸入帳號"});
            return false;
        }
        if(values.password !== "" || values.password_confirmation !== ""){
            if(values.password_confirmation !== values.password){
                setSnack({open: true, text: "確認密碼不一致"});
                return false;
            }
        }
        if(values.name === ""){
            setSnack({open: true, text: "請輸入姓名"});
            return false;
        }
        if(values.phone === ""){
            setSnack({open: true, text: "請輸入電話號碼"});
            return false;
        }
        if(values.email === ""){
            setSnack({open: true, text: "請輸入Email"});
            return false;
        }
        if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)){
            setSnack({open: true, text: "信箱格式不正確"});
            return false;
        }

        return true;
    }

    const handleSelect = (status) => {
        let result = [];
        if(status === "1"){
            brandList.map((item) => result.push(item.id));
        }
        setBrand(result);
    }

    return (
        <div className="pdx-3">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="帳號狀態">
                        <SwitchGroup>
                            <Switch checked={switchToggle} onChange={() => setSwitchToggle(!switchToggle)}/>
                            <span className="ml-2 font-size-14">{switchToggle ? "啟用" : "停用"}</span>
                        </SwitchGroup>
                    </FormGroup>
                    <FormGroup label="設定帳號" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="username"
                                type="text"
                                spellCheck="false"
                                autoComplete="new-username"
                                placeholder="請輸入帳號"
                                {...register("username")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="設定密碼">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="password"
                                type={passwordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="new-password"
                                placeholder="若無需更改請留空"
                                {...register("password")}
                            />
                            <PasswordShowButton passwordShow={passwordShow} setPasswordShow={setPasswordShow} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="確認密碼">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="password_confirmation"
                                type={repasswordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="若無需更改請留空"
                                {...register("password_confirmation")}
                            />
                            <PasswordShowButton passwordShow={repasswordShow} setPasswordShow={setRepasswordShow} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="姓名" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="name"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入姓名"
                                {...register("name")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="地址">
                        <div className="d-flex">
                            <InputGroup style={{maxWidth: "100px"}}>
                                <input
                                    tabIndex="1"
                                    name="postal_code"
                                    type="text"
                                    spellCheck="false"
                                    autoComplete="off"
                                    placeholder="郵政編碼"
                                    {...register("postal_code")}
                                />
                            </InputGroup>
                            <InputGroup style={{flex: "1", marginLeft: "10px"}}>
                                <input
                                    tabIndex="1"
                                    name="country"
                                    type="text"
                                    spellCheck="false"
                                    autoComplete="off"
                                    placeholder="國家"
                                    {...register("country")}
                                />
                            </InputGroup>
                        </div>
                        <div className="d-flex mgt-2">
                            <InputGroup style={{flex: "1"}}>
                                <input
                                    tabIndex="1"
                                    name="province"
                                    type="text"
                                    spellCheck="false"
                                    autoComplete="off"
                                    placeholder="省"
                                    {...register("province")}
                                />
                            </InputGroup>
                            <InputGroup style={{flex: "1", marginLeft: "10px"}}>
                                <input
                                    tabIndex="1"
                                    name="city"
                                    type="text"
                                    spellCheck="false"
                                    autoComplete="off"
                                    placeholder="城市"
                                    {...register("city")}
                                />
                            </InputGroup>
                        </div>
                        <div className="d-flex mgt-2">
                            <InputGroup>
                                <input
                                    tabIndex="1"
                                    name="address1"
                                    type="text"
                                    spellCheck="false"
                                    autoComplete="off"
                                    placeholder="地址1"
                                    {...register("address1")}
                                />
                            </InputGroup>
                        </div>
                        <div className="d-flex mgt-2">
                            <InputGroup>
                                <input
                                    tabIndex="1"
                                    name="address2"
                                    type="text"
                                    spellCheck="false"
                                    autoComplete="off"
                                    placeholder="地址2"
                                    {...register("address2")}
                                />
                            </InputGroup>
                        </div>
                    </FormGroup>
                    <FormGroup label="電話號碼" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="phone"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入電話號碼"
                                {...register("phone")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="Email" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="email"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入Email"
                                {...register("email")}
                            />
                        </InputGroup>
                    </FormGroup>

                    <FormGroup label="適用品牌">
                        { brandList.map((item, index) => <SwitchItem item={item} key={index} brand={brand} setBrand={setBrand} />) }
                        <div className="text-danger font-size-12 mgt-1">備註：若全品牌皆適用則不用選取品牌</div>
                        <div className="mgt-1">
                            <button type="button" className="common-btn table-btn black my-2" onClick={() => handleSelect("1")}>全選</button>
                            <button type="button" className="common-btn table-btn black my-2 ml-3" onClick={() => handleSelect("0")}>取消全選</button>
                        </div>
                    </FormGroup>
                    <FormGroup label="運費 ($)">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="delivery_fee"
                                type="number"
                                inputMode="decimal"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入運費"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => handleKeyDown(e, { number: true, float: true })}
                                {...register("delivery_fee")}
                            />
                        </InputGroup>
                        <div className="text-danger font-size-12 mgt-1">備註：若無填寫則自動套用預設運費</div>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <ConfirmButton
                        className="submit-btn"
                        onValidate={handleValidate}
                        onSuccess={onSubmit}
                        confirmText="確定要送出嗎？"
                        confirm={{show: true, text: '送出'}}
                        cancel={{show: true}}
                    />
                </div>
            </form>
        </div>
    )
}

const SwitchItem = (props) => {
    const { item, brand, setBrand } = props;
    const [ toggle, setToggle ] = useState(false);

    useEffect(() => {
        let index = brand.findIndex(e => e === item.id);
        setToggle(index !== -1 ? true : false);
    }, [brand])
 
    const handleChange = () => {
        let result = Array.from(brand);
        setToggle(!toggle);
        let index = result.findIndex(e => e === item.id);

        if(index !== -1){
            result.splice(index, 1);
        }else{
            result.push(item.id);
        }
        setBrand(result);
    }
    
    return (
        <div className="switch-group">
            <Switch checked={toggle} onChange={() => handleChange()} />
            <span className="ml-2 font-size-14">{item.name}</span>
        </div>
    )
}