import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { FormGroup, InputGroup, TextGroup } from 'components/Form/FormGroup';
import { getAdminProfile, updateAdmin } from "services/authService";
import ConfirmButton from 'components/ConfirmButton';
import { GlobalContext } from 'store/context/GlobalContext';
import PasswordShowButton from 'components/Form/PasswordShowButton';

export default function Edit() {
    let mounted = true;
    const { id } = useParams();
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const [ passwordShow, setPasswordShow ] = useState(false);
    const [ repasswordShow, setRepasswordShow ] = useState(false);
    const [ data, setData ] = useState({});
    const { handleSubmit, formState: { isDirty, isValid, errors }, register, reset, setValue, getValues } = useForm({ mode: 'onChange', shouldUnregister: true });
    
    const fetchData = useCallback(async() => {
        setLoader(true);
        let result = await getAdminProfile(id);
        if(mounted){
            reset({
                username: result.username,
                name: result.name,
                role: result.role,
            })
            setData(result);
            setLoader(false);
        }
    }, [])

    useEffect(() => {
        fetchData();
        return () => mounted = false;
    }, [fetchData])

    const onSubmit = () => {
        let values = getValues();

        setLoader(true);
        updateAdmin(id, values).then(res => {
            setSnack({open: true, text: res.messages});
            setValue("password", "");
            setValue("password_confirmation", "");
            fetchData();
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const hanleValidate = () => {
        let values = getValues();

        if(values.username === ""){
            setSnack({open: true, text: "請輸入管理員帳號"});
            return false;
        }
        if(values.password_confirmation !== "" && values.password === ""){
            setSnack({open: true, text: "請輸入新密碼"});
            return false;
        }
        if(values.password !== "" && values.password_confirmation !== ""){
            if(values.password_confirmation !== values.password){
                setSnack({open: true, text: "確認密碼不一致"});
                return false;
            }
        }
        if(values.name === ""){
            setSnack({open: true, text: "請輸入管理員暱稱"});
            return false;
        }

        return true;
    }

    const handleRole = (role) => {
        switch (role) {
            case "warehouse": return "倉庫管理員";
            case "accounting": return "財務";
            // case "editor": return "編輯者";
            case "admin": return "管理員";
            case "super": return "總管理員";
            case "developer": return "系統開發者";
            default: return "-";
        }
    }

    return (
        <div className="pdx-3">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="管理員帳號" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="username"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入管理員帳號"
                                {...register("username")}
                                defaultValue={data.username}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="新密碼">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="password"
                                type={passwordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="new-password"
                                placeholder="若無需更改請留空"
                                {...register("password")}
                            />
                            <PasswordShowButton passwordShow={passwordShow} setPasswordShow={setPasswordShow} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="確認新密碼">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="password_confirmation"
                                type={repasswordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="new-password"
                                placeholder="若無需更改請留空"
                                {...register("password_confirmation")}
                            />
                            <PasswordShowButton passwordShow={repasswordShow} setPasswordShow={setRepasswordShow} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="管理員暱稱" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="name"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入管理員暱稱"
                                {...register("name")}
                                defaultValue={data.name}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="管理員權限" required>
                        <select
                            name="role"
                            value={data.role ? data.role : ""}
                            {...register("role",{
                                onChange: (e) => {
                                    setData({...data, role: e.target.value});
                                    setValue("role", e.target.value);
                                }
                            })}
                        >
                            <option value="" disabled>請選擇管理員權限</option>
                            <option value="warehouse">倉庫管理員</option>
                            <option value="accounting">財務</option>
                            {/* <option value="editor">編輯者</option> */}
                            <option value="admin">管理員</option>
                            <option value="super">總管理員</option>
                        </select>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <ConfirmButton
                        className="submit-btn"
                        onValidate={hanleValidate}
                        onSuccess={onSubmit}
                        confirmText="確定要更改嗎？"
                        confirm={{show: true, text: '確定'}}
                        cancel={{show: true}}
                    />
                </div>
            </form>
        </div>
    )
}