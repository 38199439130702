import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';

const Thead = (props) => {
    const { column, data, checkbox, checkboxCallback, orderByCallback } = props;
    const [ orderBy, setOrderBy ] = useState({order: -1, sort: 'desc'});
    const [ checkboxStatus, setCheckboxStatus ] = useState("");
    const theadRef = useRef(null);
    let mainRef = document.getElementsByTagName('main')[0];
    const [ shadow, setShadow ] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if(theadRef.current){
                let refViewportOffset = theadRef.current.getBoundingClientRect();
                if(refViewportOffset.top <= 60){
                    setShadow(true);
                }else{
                    setShadow(false);
                }
            }
        }

        mainRef.addEventListener("scroll", handleScroll);
        
        return () => mainRef.removeEventListener("scroll", handleScroll);
    }, [theadRef])

    useEffect(() => {
        if(checkbox.data){
            if(checkbox.data.length > 0 && checkbox.data.length < data.length){
                setCheckboxStatus("indeterminate");
                return;
            }
            if(checkbox.data.length === data.length){
                setCheckboxStatus("checked");
                return;
            }
            if(checkbox.data.length === 0){
                setCheckboxStatus("");
                return;
            }
        }
    }, [checkbox.data])

    const handleOrderByChange = (key, item) => {
        let data = {order: item.key};
        if(orderBy.order !== item.key) {
            data = {...data, sort: 'desc'}
        } else {
            data = {...data, sort: orderBy.sort === 'asc' ? 'desc' : 'asc'}
        }
        setOrderBy(data);
        orderByCallback(data);
    }

    return (
        <thead style={{boxShadow: shadow ? "0 1px 3px 0 rgba(0,0,0,0.1)" : "none"}} ref={theadRef}>
            <tr>
                <th colSpan="100%" className="pd-0" style={{borderBottom: "0"}}>
                    <div className="sticky-fit-width sticky-left" style={{top: "0", zIndex: "1"}}>
                        <Collapse
                            in={(checkbox.batchBar.alwaysOpen && checkbox.batchBar.alwaysOpen) || (checkbox.data && checkbox.data.length > 0)}
                            timeout={300}
                            unmountOnExit
                        >
                            <div className="batch-bar">
                                <div className="d-flex align-items-center">
                                    <div className="font-size-16">批次處理</div>
                                    <div className="font-size-12 mgl-3">已選取 {checkbox.data && checkbox.data.length} 個</div>
                                </div>
                                <div className="d-flex align-items-center">{checkbox.batchBar.tools && checkbox.batchBar.tools}</div>
                            </div>
                        </Collapse>
                    </div>
                </th>
            </tr>
            { column && (
                <tr>
                    { checkbox.open && (
                        <th className="table-border table-border-right sticky-left" style={{minWidth: '70px', width: '70px', zIndex: "3"}}>
                            <Checkbox
                                sx={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}
                                onChange={() => checkboxCallback(checkboxStatus === "" ? "checked" : "")}
                                indeterminate={checkboxStatus === "indeterminate"}
                                checked={checkboxStatus === "checked"}
                            />
                        </th>
                    ) }
                    {column.map((item, key) => {
                        return (
                            item.order ? (
                                <th className="order" key={key} onClick={() => handleOrderByChange(key, item)}>
                                    {item.name}
                                    <i className={`fas fa-sort-up ${orderBy.order === item.key && orderBy.sort === 'asc' && 'text-dark'}`}></i>
                                    <i className={`fas fa-sort-down ${orderBy.order === item.key && orderBy.sort === 'desc' && 'text-dark'}`}></i>
                                </th>
                            ) : <th key={key}>{item.name}</th>
                        )
                    })}
                </tr>
            )}
        </thead>
    );
}

export default Thead;