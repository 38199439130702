import React from 'react';

const WrapperContainer = ({ className, sx, children }) => {
    return (
        <div className={`wrapper-container${className ? ` ${className}` : ''}`} style={sx}>
            { children }
        </div>
    )
}

const WrapperHeader = ({ className, sx, children }) => {
    return (
        <div className={`wrapper-header${className ? ` ${className}` : ''}`} style={sx}>
            { children }
        </div>
    )
}

const WrapperTitle = ({ className, sx, children }) => {
    return (
        <div className={`wrapper-title${className ? ` ${className}` : ''}`} style={sx}>
            { children }
        </div>
    )
}

const WrapperBreadcrumb = ({ className, sx, children }) => {
    return (
        <ul className={`breadcrumb-list${className ? ` ${className}` : ''}`} style={sx}>
            { children }
        </ul>
    )
}

const WrapperContent = ({ className, sx, children }) => {
    return (
        <section className={`wrapper-content${className ? ` ${className}` : ''}`} style={sx}>
            { children }
        </section>
    )
}

export default WrapperContainer;

export { WrapperHeader, WrapperTitle, WrapperContent, WrapperBreadcrumb };
