import React, { useState } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import EditIcon from '@mui/icons-material/Edit';

const SpeedDialMenu = ({ menuList }) => {
    const [ open, setOpen ] = useState(false);

    return (
        <SpeedDial
            ariaLabel="SpeedDial openIcon example"
            className="tools-btn"
            icon={<SpeedDialIcon openIcon={<EditIcon />} />}
            onClick={() => setOpen(!open)}
            open={open}
        >
            {menuList.map((item, key) => 
            <SpeedDialAction
                key={key}
                icon={item.icon}
                tooltipTitle={item.name}
                tooltipOpen
            />)}
        </SpeedDial>
    )
}


export default SpeedDialMenu;