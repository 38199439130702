import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as action from "store/actions";
import Skeleton from '@mui/material/Skeleton';
import LightBox from '../LightBox';
import ConfirmBox from '../ConfirmBox';
import { GlobalContext } from 'store/context/GlobalContext';
// import { getCart, addToCart } from 'services/authService';
import Spinner from 'react-bootstrap/Spinner';
import useKeyboard from 'components/useKeyboard';
import { addOrderItem } from 'services/authService';

const ProductList = (props) => {
    // const viewMode = useSelector((state) => state.ProductViewMode.viewMode);
    const viewMode = "list";
    const { data, setData, loading, fetchOrder } = props;

    return (
        loading ? (
            <PageLoading viewMode={viewMode} />
        ) : (
            data.length > 0 ? (
                <ul className={`product-list view-mode-${viewMode}`}>
                    { data.map((item, index) => <ProductItem item={item} key={item.id} setData={setData} fetchOrder={fetchOrder} />)}
                </ul>
            ) : (
                <div className="text-center" style={{margin: "100px 0"}}>
                    <i className="fal fa-file-search fa-5x"></i>
                    <div className="font-size-18 text-main mgt-4">Product not found</div>
                </div>
            )
        )
    )
}

const PageLoading = ({viewMode}) => {
    return (
        <ul className={`product-list view-mode-${viewMode}`}>
            {
                Array(parseInt(12)).fill().map((item, index) =>
                    <li className="product-item" key={index}>
                        <div className="product-container">
                            <div className="product-image" style={{backgroundImage: "none"}}>
                                <Skeleton variant="rectangular" height="100%" width="100%" style={{position: "absolute", top: "0", left: "0"}} />
                            </div>
                            <div className="product-content">
                                <div className="product-info">
                                    <div className="product-category">
                                        <Skeleton variant="rectangular" height="12px" width="50px" style={{borderRadius: "4px"}} />
                                    </div>
                                    <div className="product-name">
                                        <Skeleton variant="rectangular" height="12px" width="80px" style={{borderRadius: "4px", marginTop: "12px"}} />
                                    </div>
                                    <div className="product-name">
                                        <Skeleton variant="rectangular" height="12px" width="80px" style={{borderRadius: "4px", marginTop: "12px"}} />
                                    </div>
                                </div>
                                <div className="product-btn">
                                    <Skeleton variant="rectangular" height="30px" width="100%" style={{borderRadius: "50px"}} />
                                </div>
                            </div>
                        </div>
                    </li>
                )
            }
        </ul>
    )
}

const ProductItem = (props) => {
    const { id } = useParams();
    const { item, setData, fetchOrder } = props;
    const [ lightboxToggle, setLightboxToggle ] = useState(false);
    const [ confirmBoxToggle, setConfirmBoxToggle] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ confirmText, setConfirmText ] = useState();
    const { handleKeyDown } = useKeyboard();
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;

    // const handleClose = () => {
    //     setLightboxToggle(false);
    //     setTimeout(() => {
    //         setQuantity("1")
    //     }, 300);
    // }

    // const handleChange = (value, maxValue) => {
    //     if(parseInt(value) > maxValue){
    //         setQuantity(maxValue);
    //     }else{
    //         setQuantity(value);
    //     }
    // }

    const handleAddCart = () => {
        // setLoading(true);
        // addToCart(item.uuid, {quantity: quantity}).then(res => {
        //     setFreshCart(true);
        //     setLightboxToggle(false);
        //     setConfirmText(<>
        //         <div className="text-center text-success font-size-22 mgb-2"><i className="fas fa-check-circle mgr-1"></i>Success</div>
        //         <div>{quantity} x『{item.name} {item.zh_name}』 has been added to your shopping cart</div>
        //     </>)
        //     setTimeout(() => {
        //         setLoading(false);
        //         setConfirmBoxToggle(true);
        //     }, 300);
        // }).catch(err => {
        //     setConfirmText(<>
        //         <div className="text-center text-danger font-size-22 mgb-2"><i className="fas fa-exclamation-circle mgr-1"></i>Fail</div>
        //         <div>{err.messages.error}</div>
        //     </>);
        //     setTimeout(() => {
        //         setLoading(false);
        //         setConfirmBoxToggle(true);
        //     }, 300);
        // })
    }

    const handleAddOrder = () => {
        setLoader(true);
        addOrderItem(id, {id: item.id, quantity: item.quantity}).then(res => {
            setSnack({open: true, text: res.messages});
            fetchOrder();
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, theme: 'danger', text: err.messages.error});
            setLoader(false);
        })
    }

    const handleChange = (value, maxValue) => {
        let max = maxValue;
        if(parseInt(value) > max){
            // setQuantity(max);
            setData(item, parseInt(max))
        }else{
            // setQuantity(value);
            setData(item, parseInt(value))
        }
    }

    return (<>
        <li className="product-item">
            <div className="product-container">
                {
                    (item.image !== "" && item.image) ? (
                        <div className="product-image" style={{backgroundImage: `url(${item.image})`}}></div>
                    ) : (
                        <div className="product-image"></div>
                    )
                }
                <div className="product-content">
                    <div className="product-info">
                        <div className="product-name">SKU：{item.sku}</div>
                        <div className="product-name">{item.name}</div>
                        <div className="product-name">{item.zh_name}</div>
                        {item.description && <div className="product-name">description：{item.description}</div>}
                    </div>
                </div>
            </div>
            <div className="quantity-box mgt-3">
                <button type="button" className="quantity-btn" onClick={() => parseInt(item.quantity) > 1 && setData(item, parseInt(item.quantity) - 1)} disabled={parseInt(item.quantity) === 1}>-</button>
                <input
                    tabIndex="1"
                    type="number"
                    className="quantity-input"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    autoComplete="off"
                    spellCheck="false"
                    value={item.quantity}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleChange(e.target.value, item.stock+item.reserve_stock)}
                    onKeyDown={(e) => handleKeyDown(e, { number: true })}
                    onBlur={(e) => (e.target.value === "" || parseInt(e.target.value) < 1) ? setData(item, "1") : setData(item, parseFloat(e.target.value)) }
                />
                <button type="button" className="quantity-btn" onClick={() => setData(item, parseInt(item.quantity)+1)} disabled={parseInt(item.quantity) >= item.stock+item.reserve_stock}>+</button>
            </div>
            <div className="pdt-3 d-flex align-items-center justify-content-between">
                <div>
                    <div className="text-main font-size-14 fw-bold">Stock：{item.stock}</div>
                    <div className="text-main font-size-14 fw-bold">Reserve Stock：{item.reserve_stock}</div>
                </div>
                <div className="product-btn">
                    <button type="button" onClick={() => handleAddOrder()}>{!isNaN(item.quantity) ? `$ ${Math.round(item.price*item.quantity*10000)/10000}` : '$ 0'}</button>
                </div>
            </div>
        </li>
        {/* <LightBox
            open={lightboxToggle}
            callback={() => handleClose()}
            className="product-light-box"
            cross
        >
            <div className="light-box-header pd-3 bg-white">
                <div className="text-main fw-bold font-size-16">Add To Cart</div>
            </div>
            <div className="light-box-body pdx-3 bg-white">
                <div className="d-flex align-items-stretch">
                    {
                        item.image !== "" ? (
                            <div className={`item-image${item.stock <= 0 ? ` sold-out` : ''}`} style={{backgroundImage: `url(${item.image})`}}></div>
                        ) : (
                            <div className={`item-image${item.stock <= 0 ? ` sold-out` : ''}`}></div>
                        )
                    }
                    <div className="d-flex flex-column justify-content-between mgl-3" style={{flex: "1"}}>
                        <div className="item-info">
                            <div className="item-category">{item.category.name}</div>
                            <div className="item-name">{item.name}</div>
                            <div className="item-name">{item.zh_name}</div>
                        </div>
                        {
                            item.stock > 0 &&
                            <div className="quantity-box">
                                <button type="button" className="quantity-btn" onClick={() => parseInt(quantity) > 1 && setQuantity(parseInt(quantity) - 1)} disabled={parseInt(quantity) === 1}>-</button>
                                <input
                                    tabIndex="1"
                                    type="number"
                                    className="quantity-input"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    autoComplete="off"
                                    spellCheck="false"
                                    value={quantity}
                                    onChange={(e) => handleChange(e.target.value, item.stock)}
                                    onKeyDown={(e) => handleKeyDown(e, { number: true })}
                                    onBlur={(e) => (e.target.value === "" || parseInt(e.target.value) < 1) ? setQuantity("1") : parseInt(e.target.value) > item.stock ? setQuantity(item.stock) : setQuantity(parseFloat(e.target.value)) }
                                />
                                <button type="button" className="quantity-btn" onClick={() => parseInt(quantity) < item.stock && setQuantity(parseInt(quantity) + 1)} disabled={parseInt(quantity) >= item.stock}>+</button>
                            </div>
                        }
                    </div>
                </div>
                <div className="mgy-4">
                    <div className="mgb-1">Brands：{item.brands.map((brand, index) => <span key={index}>{brand.name}{item.brands.length !== index+1 && "、"}</span>)}</div>
                    <div className="mgb-1">Item Description：2.5kg/6 Bottles/Case</div>
                    <div className="mgb-1">SKU：{item.sku}</div>
                    <div className="mgb-1">Weight(lbs)：{item.weight}</div>
                    <div className="mgb-1">Dimension(Cu in)：{item.dimension}</div>
                    <div className="mgb-1 text-main fw-bold">Price ($)：{item.price}</div>
                    <div className="text-main fw-bold">Stock: {item.stock}</div>
                </div>
            </div>
            <div className="light-box-footer bg-white">
                { item.stock > 0 && <div className="mgb-2 font-size-16 fw-bold">Item Total：$ {(Math.round(item.price*quantity*10000)/10000)}</div> }
                <button type="button" className="add-to-cart-btn" onClick={() => handleAddCart()} disabled={item.stock <= 0 || quantity < 1 || loading}>Add To Cart{loading && <Spinner className="mgl-2" animation="border" size="sm" />}</button>
            </div>
        </LightBox>
        <ConfirmBox
            toggle={confirmBoxToggle}
            setToggle={() => {
                setConfirmBoxToggle(false);
                setTimeout(() => {
                    setQuantity("1");
                }, 300)
            }}
            confirmCallback={() => {
                setConfirmBoxToggle(false);
                setTimeout(() => {
                    setQuantity("1");
                }, 300)
            }}
            confirmText={confirmText}
            confirm={{show: true, text: 'Ok'}}
            cancel={{show: false, text: "Cancel"}}
        /> */}
    </>)
}

export default ProductList;