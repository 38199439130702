import Http from "utils/Http";
import * as action from "store/actions";

let BASE_API_URL;
let API_URL;

if(process.env.REACT_APP_ENVIRONMENT === 'development') {
	BASE_API_URL = process.env.REACT_APP_TEST_BASE_API_URL;
	API_URL = process.env.REACT_APP_TEST_API_URL;
} else {
	BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
	API_URL = process.env.REACT_APP_API_URL;
}

export function login(credentials) {
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.post(API_URL+"/auth/login", credentials)
				.then(res => {
					dispatch(action.authLogin(res.data));
					return resolve(res.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
		});
}

export function refreshToken() {
	let credentials = {
		grant_type: "refresh_token",
		refresh_token: localStorage.getItem('refresh_token')
	}
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.post(API_URL+"/auth/refreshToken", credentials)
				.then(res => {
					dispatch(action.authRefreshToken(res.data));
					return resolve(res.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
		});
}

export function logout() {
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.post(API_URL+"/logout")
				.then(res => {
					dispatch(action.authLogout());
					return resolve(res.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
		});
}

export function fetchUser() {
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.get(API_URL+"/administrator/profile")
				.then(res => {
					dispatch(action.authUser(res.data));
					return resolve();
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err);
					}
				});
		});
}

//儀表板---------------------------------------------------------------------------------------
export function getOrdersStatus() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/counts/orders/status")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getOrdersPayment() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/counts/orders/payment")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//-------------------------------------------------------------------------------------------

//管理員列表 Administrator---------------------------------------------------------------------------------------
export function getAdminList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/administrator", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getAdminProfile(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/administrator/profile/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createAdmin(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/administrator", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateAdminProfile(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/administrator/profile", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateAdmin(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/administrator/profile/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteAdmin(id) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/administrator/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//會員列表---------------------------------------------------------------------------------------
export function userList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/users", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createUser(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/users", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUserItem(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/users/"+ id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUser(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/users/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
export function searchUser(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/users/search", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//最新消息---------------------------------------------------------------------------------------
export function getNewsList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/news", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getNewsItem(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/news/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createNews(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/news", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateNews(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/news/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateNewsStatus(id) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/news/status/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteNews(id) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/news/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//活動訊息---------------------------------------------------------------------------------------
export function getEventsList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/events", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getEventItem(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/events/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createEvent(credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/events", credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateEvent(id, credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/events/"+id, credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateEventStatus(id) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/events/status/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteEvent(id) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/events/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//廣告輪播管理---------------------------------------------------------------------------------------
export function getBannerList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/banner", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getBannerItem(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/banner/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createBanner(credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/banner", credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateBanner(id, credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/banner/"+id, credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateBannerStatus(id) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/banner/status/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteBanner(id) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/banner/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//新手指南---------------------------------------------------------------------------------------
export function getUserGuide() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/guide")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUserGuide(credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/guide", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//關於我們---------------------------------------------------------------------------------------
export function getAbout() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/about")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateAbout(credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/about", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//品牌列表---------------------------------------------------------------------------------------
export function getBrandsList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/brands", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getBrandsSearch(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/brands/search", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getBrandItem(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/brands/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createBrand(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/brands", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateBrand(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/brands/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateBrandStatus(id) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/brands/"+id+"/status")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteBrand(id) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/brands/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//分類列表---------------------------------------------------------------------------------------
export function getCategoryList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/category", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getCategorySearch(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/category/search", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getCategoryItem(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/category/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createCategory(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/category", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateCategory(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/category/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateCategoryStatus(id) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/category/"+id+"/status")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteCategory(id) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/category/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//商品列表---------------------------------------------------------------------------------------
export function getAllProductsList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/products/all", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getProductsList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/products", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getProductItem(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/products/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createProduct(credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/products", credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateProduct(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/products/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateProductStatus(id) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/products/"+id+"/status")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateProductShow(id) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/products/"+id+"/show")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteProduct(id) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/products/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//訂單列表---------------------------------------------------------------------------------------
export function getOrdersList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/orders", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getOrderItem(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/orders/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function exportPickUp(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/orders/pickup", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function exportOrders(credentials) {
	const config = {
		headers:
            {
                'Content-Disposition': "attachment; filename=template.xlsx",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
        responseType: 'arraybuffer',
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+'/orders/export', credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateOrderItem(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/orders/"+id+"/update", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422 || statusCode === 500) {
					reject(err.response.data);
				}
			});
	});
}

export function updateOrderItemProduct(uuid, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/orders/item/"+uuid+"/update", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422 || statusCode === 500) {
					reject(err.response.data);
				}
			});
	});
}

export function addOrderItem(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/orders/"+id+"/addItem", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422 || statusCode === 500) {
					reject(err.response.data);
				}
			});
	});
}

export function updateOrderStatus(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/orders/"+id+"/status", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateOrderPaymentStatus(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/orders/"+id+"/paymentStatus", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateOrderBatchStatus(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/orders/batch/status", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateOrderBatchPaymentStatus(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/orders/batch/paymentStatus", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteOrderItemProduct(uuid) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/orders/item/"+uuid+"/remove")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422 || statusCode === 500) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//參數管理---------------------------------------------------------------------------------------
export function getDeliveryConfig() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/cart/config/delivery")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateDeliveryConfig(credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/cart/config/delivery", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getOrderConfig() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/cart/config/order")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateOrderConfig(credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/cart/config/order", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------