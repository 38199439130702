import Page403 from 'pages/403';
import Page404 from 'pages/404';

import Login from 'pages/Auth/Login';

import Dashboard from 'pages/Dashboard';

// System----------------------------------------------
import SystemAdministrator from 'pages/System/Administrator';
import SystemAdministratorCreate from 'pages/System/Administrator/Create';
import SystemAdministratorEdit from 'pages/System/Administrator/Edit';
import SystemAdministratorProfile from 'pages/System/Administrator/Profile';

// Member----------------------------------------------
import MemberList from 'pages/Member/List';
import MemberListEdit from 'pages/Member/List/Edit';
import MemberListCreate from 'pages/Member/List/Create';

// Order----------------------------------------------
import OrderList from 'pages/Order/List';
import OrderListView from 'pages/Order/List/View';
import OrderListPickUp from 'pages/Order/List/PickUp';

// ElectronicCommerce----------------------------------------------
import ElectronicCommerceBrand from 'pages/ElectronicCommerce/Brand';
import ElectronicCommerceBrandCreate from 'pages/ElectronicCommerce/Brand/Create';
import ElectronicCommerceBrandEdit from 'pages/ElectronicCommerce/Brand/Edit';
import ElectronicCommerceCategory from 'pages/ElectronicCommerce/Category';
import ElectronicCommerceCategoryCreate from 'pages/ElectronicCommerce/Category/Create';
import ElectronicCommerceCategoryEdit from 'pages/ElectronicCommerce/Category/Edit';
import ElectronicCommerceProduct from 'pages/ElectronicCommerce/Product';
import ElectronicCommerceProductCreate from 'pages/ElectronicCommerce/Product/Create';
import ElectronicCommerceProductEdit from 'pages/ElectronicCommerce/Product/Edit';

// Config----------------------------------------------
import ConfigShipping from 'pages/Config/Shipping';
import ConfigOrder from 'pages/Config/Order';

// Application----------------------------------------------
import ApplicationNews from 'pages/Application/News';
import ApplicationNewsCreate from 'pages/Application/News/Create';
import ApplicationNewsEdit from 'pages/Application/News/Edit';
import ApplicationEvents from 'pages/Application/Events';
import ApplicationEventsCreate from 'pages/Application/Events/Create';
import ApplicationEventsEdit from 'pages/Application/Events/Edit';

const routes = [
    {
        path: '/403',
        exact: true,
        auth: true,
        component: Page403,
        title: '403 Error',
        wrapperHeader: false,
    },
    {
        path: '/404',
        exact: true,
        auth: true,
        component: Page404,
        title: '404 Error',
        wrapperHeader: false,
    },
    {
        path: '/',
        exact: true,
        auth: false,
        component: Login,
        title: '登入',
        layout: 'public',
    },
    {
        path: '/login',
        exact: true,
        auth: false,
        component: Login,
        title: '登入',
        layout: 'public',
    },
    {
        path: '/order/pickup',
        exact: true,
        auth: true,
        component: OrderListPickUp,
        title: '出貨單',
    },
    {
        path: '/dashboard',
        exact: true,
        auth: true,
        component: Dashboard,
        title: '儀表板',
        hasMain: true,
        layout: 'private',
    },
    {
        path: '/system',
        exact: true,
        auth: true,
        component: SystemAdministrator,
        title: '系統管理',
        layout: 'private',
        routes: [
            {
                path: '/Administrator',
                exact: true,
                auth: true,
                component: SystemAdministrator,
                title: '管理員列表',
                hasMain: true,
                layout: 'private',
                routes: [
                    {
                        path: '/create',
                        exact: true,
                        auth: true,
                        component: SystemAdministratorCreate,
                        title: '新增管理員',
                        role: ['developer', 'super'],
                        layout: 'private',
                    },
                    {
                        path: '/profile',
                        exact: true,
                        auth: true,
                        component: SystemAdministratorProfile,
                        title: '編輯管理員',
                        layout: 'private',
                    },
                    {
                        path: '/edit/:id',
                        exact: true,
                        auth: true,
                        component: SystemAdministratorEdit,
                        title: '編輯管理員',
                        role: ['developer', 'super'],
                        layout: 'private',
                    },
                ]
            },
        ]
    },
    {
        path: '/member',
        exact: true,
        auth: true,
        component: MemberList,
        title: '會員管理',
        hasMain: true,
        layout: 'private',
        routes: [
            {
                path: '/List',
                exact: true,
                auth: true,
                component: MemberList,
                title: '會員列表',
                hasMain: true,
                layout: 'private',
                routes: [
                    {
                        path: '/create',
                        exact: true,
                        auth: true,
                        component: MemberListCreate,
                        title: '新增會員',
                        layout: 'private',
                    },
                    {
                        path: '/edit/:id',
                        exact: true,
                        auth: true,
                        component: MemberListEdit,
                        title: '編輯會員資料',
                        layout: 'private',
                    },
                ]
            },
        ]
    },
    {
        path: '/order',
        exact: true,
        auth: true,
        component: OrderList,
        title: '訂單管理',
        hasMain: true,
        layout: 'private',
        routes: [
            {
                path: '/List',
                exact: true,
                auth: true,
                component: OrderList,
                title: '訂單列表',
                hasMain: true,
                layout: 'private',
                routes: [
                    {
                        path: '/view/:id',
                        exact: true,
                        auth: true,
                        component: OrderListView,
                        title: '檢視訂單',
                        layout: 'private',
                    },
                ]
            },
        ]
    },
    {
        path: '/ec',
        exact: true,
        auth: true,
        component: ElectronicCommerceBrand,
        title: '電子商務管理',
        hasMain: true,
        layout: 'private',
        routes: [
            {
                path: '/Brand',
                exact: true,
                auth: true,
                component: ElectronicCommerceBrand,
                title: '品牌列表',
                hasMain: true,
                layout: 'private',
                routes: [
                    {
                        path: '/create',
                        exact: true,
                        auth: true,
                        component: ElectronicCommerceBrandCreate,
                        title: '新增品牌',
                        layout: 'private',
                    },
                    {
                        path: '/edit/:id',
                        exact: true,
                        auth: true,
                        component: ElectronicCommerceBrandEdit,
                        title: '修改品牌',
                        layout: 'private',
                    },
                ]
            },
            {
                path: '/Category',
                exact: true,
                auth: true,
                component: ElectronicCommerceCategory,
                title: '分類列表',
                hasMain: true,
                layout: 'private',
                routes: [
                    {
                        path: '/create',
                        exact: true,
                        auth: true,
                        component: ElectronicCommerceCategoryCreate,
                        title: '新增分類',
                        layout: 'private',
                    },
                    {
                        path: '/edit/:id',
                        exact: true,
                        auth: true,
                        component: ElectronicCommerceCategoryEdit,
                        title: '修改分類',
                        layout: 'private',
                    },
                ]
            },
            {
                path: '/Product',
                exact: true,
                auth: true,
                component: ElectronicCommerceProduct,
                title: '商品列表',
                hasMain: true,
                layout: 'private',
                routes: [
                    {
                        path: '/create',
                        exact: true,
                        auth: true,
                        component: ElectronicCommerceProductCreate,
                        title: '新增商品',
                        layout: 'private',
                    },
                    {
                        path: '/edit/:id',
                        exact: true,
                        auth: true,
                        component: ElectronicCommerceProductEdit,
                        title: '修改商品',
                        layout: 'private',
                    },
                ]
            },
        ]
    },
    {
        path: '/config',
        exact: true,
        auth: true,
        component: ConfigShipping,
        title: '參數管理',
        hasMain: true,
        layout: 'private',
        routes: [
            {
                path: '/Order',
                exact: true,
                auth: true,
                component: ConfigOrder,
                title: '訂單設定',
                hasMain: true,
                layout: 'private',
            },
            {
                path: '/Shipping',
                exact: true,
                auth: true,
                component: ConfigShipping,
                title: '運費設定',
                hasMain: true,
                layout: 'private',
            },
        ]
    },
    {
        path: '/application',
        exact: true,
        auth: true,
        component: ApplicationEvents,
        title: '應用管理',
        hasMain: true,
        layout: 'private',
        routes: [
            {
                path: '/Events',
                exact: true,
                auth: true,
                component: ApplicationEvents,
                title: '活動訊息',
                hasMain: true,
                layout: 'private',
                routes: [
                    {
                        path: '/create',
                        exact: true,
                        auth: true,
                        component: ApplicationEventsCreate,
                        title: '新增活動訊息',
                        layout: 'private',
                    },
                    {
                        path: '/edit/:id',
                        exact: true,
                        auth: true,
                        component: ApplicationEventsEdit,
                        title: '編輯活動訊息',
                        layout: 'private',
                    },
                ]
            },
            {
                path: '/News',
                exact: true,
                auth: true,
                component: ApplicationNews,
                title: '最新公告',
                hasMain: true,
                layout: 'private',
                routes: [
                    {
                        path: '/create',
                        exact: true,
                        auth: true,
                        component: ApplicationNewsCreate,
                        title: '新增最新公告',
                        layout: 'private',
                    },
                    {
                        path: '/edit/:id',
                        exact: true,
                        auth: true,
                        component: ApplicationNewsEdit,
                        title: '編輯最新公告',
                        layout: 'private',
                    },
                ]
            },
        ]
    },
];

export default routes;