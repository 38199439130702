import React, { useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import { FormGroup, InputGroup } from 'components/Form/FormGroup';
import { createAdmin } from "services/authService";
import ConfirmButton from 'components/ConfirmButton';
import { GlobalContext } from 'store/context/GlobalContext';
import PasswordShowButton from 'components/Form/PasswordShowButton';

export default function Create() {
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const [ passwordShow, setPasswordShow ] = useState(false);
    const [ repasswordShow, setRepasswordShow ] = useState(false);
    const { handleSubmit, formState: { isDirty, isValid, errors }, register, reset, getValues } = useForm({ mode: 'onChange', shouldUnregister: true });

    const onSubmit = () => {
        let values = getValues();

        setLoader(true);
        createAdmin(values).then(res => {
            setSnack({open: true, text: res.messages});
            reset();
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const hanleValidate = () => {
        let values = getValues();
        if(values.username === ""){
            setSnack({open: true, text: "請輸入管理員帳號"});
            return false;
        }
        if(values.password === ""){
            setSnack({open: true, text: "請輸入登入密碼"});
            return false;
        }
        if(values.password_confirmation === ""){
            setSnack({open: true, text: "請輸入確認密碼"});
            return false;
        }
        if(values.password !== "" && values.password_confirmation !== ""){
            if(values.password_confirmation !== values.password){
                setSnack({open: true, text: "確認密碼不一致"});
                return false;
            }
        }
        if(values.name === ""){
            setSnack({open: true, text: "請輸入管理員暱稱"});
            return false;
        }
        if(values.role === ""){
            setSnack({open: true, text: "請選擇管理員權限"});
            return false;
        }

        return true;
    }

    return (
        <div className="pdx-3">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="管理員帳號" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="username"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入管理員帳號"
                                {...register("username")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="管理員密碼" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="password"
                                type={passwordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入管理員密碼"
                                {...register("password")}
                            />
                            <PasswordShowButton passwordShow={passwordShow} setPasswordShow={setPasswordShow} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="確認密碼" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="password_confirmation"
                                type={repasswordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入確認密碼"
                                {...register("password_confirmation")}
                            />
                            <PasswordShowButton passwordShow={repasswordShow} setPasswordShow={setRepasswordShow} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="管理員暱稱" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="name"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入管理員暱稱"
                                {...register("name")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="管理員權限" required>
                        <select
                            name="role"
                            defaultValue=""
                            {...register("role")}
                        >
                            <option value="" disabled>請選擇管理員權限</option>
                            <option value="warehouse">倉庫管理員</option>
                            <option value="accounting">財務</option>
                            {/* <option value="editor">編輯者</option> */}
                            <option value="admin">管理員</option>
                            <option value="super">總管理員</option>
                        </select>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <ConfirmButton
                        className="submit-btn"
                        onValidate={hanleValidate}
                        onSuccess={onSubmit}
                        confirmText="確定要新增嗎？"
                        confirm={{show: true, text: '確定'}}
                        cancel={{show: true}}
                    />
                </div>
            </form>
        </div>
    )
}