import React, { useState, useEffect, useCallback, useContext } from 'react';
import moment from 'moment/moment.js';
import { Link, useParams } from 'react-router-dom';
import { getOrderItem, deleteOrderItemProduct, updateOrderItemProduct, updateOrderItem, updateOrderPaymentStatus, updateOrderStatus, exportPickUp } from 'services/authService';
import { GlobalContext } from 'store/context/GlobalContext';
import useKeyboard from 'components/useKeyboard';
import ConfirmButton from 'components/ConfirmButton';
import ProductListLightbox from 'components/page/ProductListLightbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dropdown, { DropdownToggle, DropdownMenu } from 'components/Dropdown';

export default function View() {
    let mounted = true;
    const { id } = useParams();
    const context = useContext(GlobalContext);
    const { handleKeyDown } = useKeyboard();
    const { loader, setLoader, setSnack } = context;
    const [ freshOrder, setFreshOrder ] = useState(false);
    const [ discount, setDiscount ] = useState("");
    const [ surcharge, setSurcharge ] = useState("");
    const [ note, setNote ] = useState("");
    const [ shippingMethod, setShippingMethod ] = useState("");
    const [ data, setData ] = useState({
        items: [],
    });

    const fetchData = useCallback(async() => {
        let result = await getOrderItem(id);
        if(mounted){
            setData(result.data);
            setNote(result.data.details ? result.data.details : "");
            setDiscount(result.data.discount ? result.data.discount : "0");
            setSurcharge(result.data.surcharge ? result.data.surcharge : "0");
            setShippingMethod(result.data.shipping_method);
            setFreshOrder(false);
            setLoader(false);
        }
    }, [mounted])

    useEffect(() => {
        if(!freshOrder) return;
        fetchData();
    }, [freshOrder])

    useEffect(() => {
        setLoader(true);
        fetchData();
        return () => mounted = false;
    }, [fetchData])

    const onSubmit = () => {
        setLoader(true);
        updateOrderItem(id, {note: note, discount: discount, surcharge: surcharge, shipping_method: shippingMethod}).then(res => {
            setLoader(false);
            setSnack({open: true, text: res.messages});
            setFreshOrder(true);
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, theme: 'danger', text: err.messages.error});
        })
    }

    const handleDelete = (index, uuid) => {
        let result = {...data};
        setLoader(true);
        deleteOrderItemProduct(uuid).then(res => {
            result.items.splice(index, 1);
            setData(result);
            setFreshOrder(true);
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const handlePaymentStatus = (data) => {
        switch (data.payment_status) {
            case "unpaid": return (<>
                {
                    data.status !== "cancel" &&
                    <ConfirmButton
                        className="dropdown-item"
                        onSuccess={() => handleUpdatePaymentStatus(id, "paid")}
                        confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「已繳款」嗎？`}
                        confirm={{show: true, text: '確定'}}
                        cancel={{show: true}}
                    >
                        <i className="fas fa-money-check"></i>設為已繳款
                    </ConfirmButton>
                }
            </>);
            case "paid": return (<>
                {
                    data.status !== "cancel" &&
                    <ConfirmButton
                        className="dropdown-item"
                        onSuccess={() => handleUpdatePaymentStatus(id, "unpaid")}
                        confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「未繳款」嗎？`}
                        confirm={{show: true, text: '確定'}}
                        cancel={{show: true}}
                    >
                        <i className="fas fa-money-check"></i>設為未繳款
                    </ConfirmButton>
                }
            </>);
            default: return (<></>);
        }
    }

    const handleUpdatePaymentStatus = (id, status) => {
        setLoader(true);
        updateOrderPaymentStatus(id, { status: status }).then(res => {
            fetchData();
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const handleStatus = (data) => {
        switch (data.status) {
            case "pending": return (<>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(id, "processing")}
                    confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「處理中」嗎？`}
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-angle-double-right"></i>狀態設為處理中
                </ConfirmButton>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(id, "cancel")}
                    confirmText="確定要取消訂單嗎？"
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-trash-alt"></i>取消訂單
                </ConfirmButton>
            </>);
            case "processing": return (<>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(id, "picked")}
                    confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「撿貨完成」嗎？`}
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-angle-double-right"></i>狀態設為撿貨完成
                </ConfirmButton>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(id, "pending")}
                    confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「待審核」嗎？`}
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-angle-double-left"></i>狀態返回待審核
                </ConfirmButton>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(id, "cancel")}
                    confirmText="確定要取消訂單嗎？"
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-trash-alt"></i>取消訂單
                </ConfirmButton>
            </>);
            case "picked": return (<>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(id, "complete")}
                    confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「已完成」嗎？`}
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-angle-double-right"></i>狀態設為已完成
                </ConfirmButton>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(id, "processing")}
                    confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「處理中」嗎？`}
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-angle-double-left"></i>狀態返回處理中
                </ConfirmButton>
            </>);
            // case "shipped": return (<>
            //     <ConfirmButton
            //         className="dropdown-item"
            //         onSuccess={() => handleUpdateStatus(id, "delivered")}
            //         confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「已收件」嗎？`}
            //         confirm={{show: true, text: '確定'}}
            //         cancel={{show: true}}
            //     >
            //         <i className="fas fa-angle-double-right"></i>狀態設為已收件
            //     </ConfirmButton>
            //     <ConfirmButton
            //         className="dropdown-item"
            //         onSuccess={() => handleUpdateStatus(id, "picked")}
            //         confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「檢貨完成」嗎？`}
            //         confirm={{show: true, text: '確定'}}
            //         cancel={{show: true}}
            //     >
            //         <i className="fas fa-angle-double-left"></i>狀態返回檢貨完成
            //     </ConfirmButton>
            // </>);
            // case "delivered": return (<>
            //     <ConfirmButton
            //         className="dropdown-item"
            //         onSuccess={() => handleUpdateStatus(id, "complete")}
            //         confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「已完成」嗎？`}
            //         confirm={{show: true, text: '確定'}}
            //         cancel={{show: true}}
            //     >
            //         <i className="fas fa-angle-double-right"></i>狀態設為已完成
            //     </ConfirmButton>
            //     <ConfirmButton
            //         className="dropdown-item"
            //         onSuccess={() => handleUpdateStatus(id, "shipped")}
            //         confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「已寄送」嗎？`}
            //         confirm={{show: true, text: '確定'}}
            //         cancel={{show: true}}
            //     >
            //         <i className="fas fa-angle-double-left"></i>狀態返回已寄送
            //     </ConfirmButton>
            // </>);
            case "complete": return (<>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(id, "picked")}
                    confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「撿貨完成」嗎？`}
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-angle-double-left"></i>狀態返回撿貨完成
                </ConfirmButton>
            </>);
            case "cancel": return (<></>);
            default: return (<></>);
        }
    }

    const handleUpdateStatus = (id, status) => {
        setLoader(true);
        updateOrderStatus(id, { status: status }).then(res => {
            fetchData();
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const handleExportPickUp = () => {
        let safariWindow = window.open("", "出貨單", 'height=500px,width=1280px');
        safariWindow.opener = null;
        safariWindow.location.href = "/order/pickup";
        exportPickUp({ids: [id]}).then(res => {
            setTimeout(() => {
                safariWindow.document.body.innerHTML = res;
                safariWindow.document.close();
                safariWindow.focus();
            }, 100)
        })
    }

    return (<>
        <div className="cart-page">
            <div className="cart-container">
                {
                    data.items.length > 0 ? (<>
                        <div className="font-size-20 fw-bold text-main mgb-1 d-flex align-items-center">Order #{data.order_number} </div>
                        <div className="badge bg-main mgb-0 text-color font-size-12 rounded-pill text-capitalize">{data.status}</div>
                        <div className="pdy-3 font-size-14 border-bottom" style={{margin: "0 -15px"}}>
                            <div className="pdx-3">會員帳號：{data.user && data.user.username}</div>
                            <div className="pdx-3 mgt-2">會員姓名：{data.name ? data.name : "-"}</div>
                            <div className="pdx-3 mgt-2">會員電話：{data.phone ? data.phone : "-"}</div>
                            <div className="pdx-3 mgt-2">會員Email：{data.email ? data.email : "-"}</div>
                            <div className="pdx-3 mgt-2">會員地址：{data.address ? data.address : "-"}</div>
                            <div className="pdx-3 mgt-2">運送方式：{data.shipping_method === "delivery" ? "寄送" : "自取"}</div>
                            {/* <div className="pdx-3 mgt-2">繳款狀態：{data.payment_status === "paid" ? <span className="text-success">已繳款</span> : <span className="text-danger">未繳款</span>}</div> */}
                            <div className="pdx-3 mgt-2">訂單成立時間：{moment(data.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
                            <div className="pdx-3 mgt-2 d-flex align-items-center">
                                {
                                    data.status !== "cancel" &&
                                    <Dropdown direction="down">
                                        <DropdownToggle className="btn btn-secondary btn-sm mgr-2">操作</DropdownToggle>
                                        <DropdownMenu flip={false}>
                                            {/* { handlePaymentStatus(data) } */}
                                            { handleStatus(data) }
                                        </DropdownMenu>
                                    </Dropdown>
                                }
                                <button className="btn btn-sm btn-success" onClick={() => handleExportPickUp()}>列印出貨單</button>
                            </div>
                        </div>
                        <ul className="cart-list">
                            { data.items.map((item, index) => <CartItem orderStatus={data.status} item={item} index={index} key={index} freshOrder={freshOrder} setFreshOrder={setFreshOrder} handleDelete={handleDelete} />) }
                        </ul>
                        {
                            (data.status === "pending" || data.status === "processing") &&
                            <div className="pdy-4 d-flex fw-bold font-size-16 border-bottom" style={{margin: "0 -15px"}}>
                                <ProductListLightbox freshOrder={freshOrder} setFreshOrder={setFreshOrder} fetchOrder={fetchData} />
                            </div>
                        }
                        <div className="pd-3 d-flex flex-wrap border-bottom" style={{margin: "0 -15px"}}>
                            <span className="fw-bold font-size-14" style={{marginTop: "4px"}}>Shipping Method：</span>
                            <RadioGroup value={shippingMethod}>
                                <FormControlLabel
                                    sx={{margin: "0"}}
                                    value="delivery"
                                    control={<Radio color="primary" size="small" />}
                                    label="Delivery"
                                    labelPlacement="end"
                                    onChange={() => setShippingMethod("delivery")}
                                />
                                <FormControlLabel
                                    sx={{margin: "0"}}
                                    value="pickup"
                                    control={<Radio color="primary" size="small" />}
                                    label="Pickup"
                                    labelPlacement="end"
                                    onChange={() => setShippingMethod("pickup")}
                                />
                            </RadioGroup>
                        </div>
                        <div className="pdy-3 d-flex flex-wrap fw-bold font-size-14">
                            <div className="col-12 col-sm-6 pd-0 mgy-1">Est Dimension (Cu in)：<span className="text-main">{data.dimension}</span></div>
                            <div className="col-12 col-sm-6 pd-0 mgy-1">Est Weight (lbs)：<span className="text-main">{data.weight}</span></div>
                            <div className="col-12 col-sm-6 pd-0 mgy-1">Subtotal：<span className="text-main">${data.subtotal}</span></div>
                            { shippingMethod === "delivery" && <div className="col-12 col-sm-6 pd-0 mgy-1">Shipping Cost：<span className="text-main">${data.delivery_fee}</span></div> }
                        </div>
                        <div className="pdy-3 fw-bold font-size-16 border-top" style={{margin: "0 -15px"}}>
                            <div className="pdx-3 d-flex align-items-center">
                                <span className="font-size-16 fw-bold text-nowrap">Discount ($)： </span>
                                <input
                                    style={{maxWidth: "150px"}}
                                    tabIndex="1"
                                    className="discount-input"
                                    type="number"
                                    pattern="[0-9]*"
                                    inputMode="decimal"
                                    autoComplete="off"
                                    spellCheck="false"
                                    value={discount}
                                    onKeyDown={(e) => handleKeyDown(e, { number: true, float: true })}
                                    onChange={(e) => setDiscount(e.target.value)}
                                    onPaste={(e) => e.preventDefault()}
                                />
                            </div>
                            <div className="pdx-3 d-flex align-items-center mgt-3">
                                <span className="font-size-16 fw-bold text-nowrap">Surcharge ($)： </span>
                                <input
                                    style={{maxWidth: "150px"}}
                                    tabIndex="1"
                                    className="discount-input"
                                    type="number"
                                    pattern="[0-9]*"
                                    inputMode="decimal"
                                    autoComplete="off"
                                    spellCheck="false"
                                    value={surcharge}
                                    onKeyDown={(e) => handleKeyDown(e, { number: true, float: true })}
                                    onChange={(e) => setSurcharge(e.target.value)}
                                    onPaste={(e) => e.preventDefault()}
                                />
                            </div>
                            <div className="pdx-3 mgt-3">Grand Total：<span className="text-main">${shippingMethod === "delivery" ? Math.round((data.subtotal+data.delivery_fee+data.surcharge-data.discount)*10000)/10000 : Math.round((data.subtotal+data.surcharge-data.discount)*10000)/10000}</span></div>
                            <div className="pdx-3 mgt-4 d-flex">
                                <span className="font-size-16 fw-bold">Note：</span>
                                <textarea rows="3" onChange={(e) => setNote(e.target.value)} spellCheck={false} value={note} />
                            </div>
                        </div>
                        <div className="submit-container">
                            <button className="submit-btn d-inline-flex justify-content-center align-items-center" style={{maxWidth: "400px"}} type="button" onClick={onSubmit} disabled={loader}>儲存</button>
                        </div>
                    </>) : (
                        !loader && (
                            <div className="text-center" style={{margin: "100px 0"}}>
                                <i className="fal fa-clipboard-list fa-5x"></i>
                                <div className="font-size-18 text-main mgt-3">No order data</div>
                                <div className="text-secondary mgt-3">This order has been deleted or no information or data can be found about the order.</div>
                                <Link className=" pdy-2 pdx-4 mgt-6 text-white d-inline-block bg-main" to="/order/List">Back to Order List</Link>
                            </div>
                        )
                    )
                }
            </div>
        </div>
    </>)
}


const CartItem = (props) => {
    const { item, index, freshOrder, setFreshOrder, orderStatus, handleDelete } = props;
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const [ loading, setLoading ] = useState(false);
    const [ quantity, setQuantity ] = useState("");
    const [ maxQuantity, setMaxQuantity ] = useState();
    const [ currentQuantity, setCurrentQuantity ] = useState("");
    const { handleKeyDown } = useKeyboard();

    useEffect(() => {
        if(!freshOrder) setLoading(false);
    } ,[freshOrder])

    useEffect(() => {
        setQuantity(item.quantity);
        setCurrentQuantity(item.quantity);
        setMaxQuantity(parseInt(item.quantity)+item.stock+item.reserve_stock);
    }, [item])

    const handleCheck = () => {
        let check = false;
        check = !item.status
        check = orderStatus !== "pending" && orderStatus !== "processing";
        return check;
    }

    const handleUpdateCartItem = (value) => {
        if(handleCheck()) return;
        setLoading(true);
        updateOrderItemProduct(item.uuid, {quantity: value}).then(res => {
            setFreshOrder(true);
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const handleChange = (value) => {
        if(handleCheck()) return;
        // console.log(maxValue)
        let max = maxQuantity;
        if(parseInt(value) > max){
            setQuantity(max);
        }else{
            setQuantity(value);
        }
    }
    
    return (
        <li className="cart-item">
            <div className="d-flex align-items-stretch flex-fill">
                {
                    (item.image !== "" && item.image) ? (
                        <div className="cart-image" style={{backgroundImage: `url(${item.image})`}}></div>
                    ) : (
                        <div className="cart-image"></div>
                    )
                }
                <div className="cart-info">
                    <div className="category">{item.category.name}</div>
                    <div className="name">{item.name}</div>
                    <div className="name">{item.zh_name}</div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="price">Price ($)：{item.price}</div>
                        { 
                            (orderStatus === "pending" || orderStatus === "processing") &&
                            <ConfirmButton
                                className="remove-btn"
                                onSuccess={() => handleDelete(index, item.uuid)}
                                confirmText="Are you sure you want to delete item?"
                                confirm={{show: true, text: 'Yes'}}
                                cancel={{show: true, text: 'Cancel'}}
                            >
                                <i className="far fa-trash-alt mgr-1"></i>remove item
                            </ConfirmButton>
                        }
                    </div>
                </div>
            </div>
            <div className="quantity-container">
                <div className="text-main fw-bold font-size-14">Item Total：${quantity !== "" ? Math.round(item.price*parseInt(quantity)*10000)/10000 : '0'}</div>
                {
                    (orderStatus === "pending" || orderStatus === "processing") ? (<>
                        <div className="quantity-box">
                            <button
                                type="button"
                                className="quantity-btn"
                                onClick={() => {
                                    if(quantity > 1){
                                        // if(quantity > item.stock){
                                        //     setQuantity(item.stock);
                                        //     handleUpdateCartItem(item.stock);
                                        // }else{
                                        //     setQuantity(quantity - 1);
                                        //     handleUpdateCartItem(quantity - 1);
                                        // }
                                        setQuantity(quantity - 1);
                                        handleUpdateCartItem(quantity - 1);
                                    } 
                                }}
                                disabled={parseInt(quantity) === 1 || loading || handleCheck()}
                                >-
                            </button>
                            <input
                                tabIndex="1"
                                type="number"
                                className="quantity-input"
                                step="1"
                                pattern="[0-9]*"
                                inputMode="numeric"
                                autoComplete="off"
                                spellCheck="false"
                                value={quantity}
                                onChange={(e) => handleChange(e.target.value)}
                                onKeyDown={(e) => handleKeyDown(e, { number: true })}
                                onWheel={(e) => e.target.blur()}
                                onPaste={(e) => e.preventDefault()}
                                onBlur={(e) => {
                                    if(e.target.value === "" || parseInt(e.target.value) < 1){
                                        setQuantity(1);
                                        handleUpdateCartItem(1);
                                    }else{
                                        setQuantity(parseFloat(e.target.value));
                                        if(currentQuantity !== parseInt(e.target.value)){
                                            handleUpdateCartItem(quantity);
                                        }
                                    }
                                }}
                                readOnly={loading || handleCheck()}
                            />
                            <button
                                type="button"
                                className="quantity-btn"
                                onClick={() => {
                                    setQuantity(quantity + 1);
                                    handleUpdateCartItem(quantity + 1);
                                }}
                                disabled={parseInt(quantity) >= maxQuantity || loading || handleCheck()}
                                >+
                            </button>
                        </div>
                    </>) : (
                        <div className="text-main fw-bold font-size-14">Qty：{quantity}</div>
                    )
                }
            </div>
            {
                (orderStatus === "pending" || orderStatus === "processing") &&
                <div className="mgt-3 text-right">
                    <div className="text-main fw-bold font-size-14">Stock：{item.stock ? item.stock : "0"}</div>
                    <div className="text-main fw-bold font-size-14">Reverse Stock：{item.reserve_stock ? item.reserve_stock : "0"}</div>
                </div>
            }
        </li>
    )
}