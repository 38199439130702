import React, { useState, useEffect, useCallback, useContext } from 'react';
import { StatContainer, StatTitle, StatList, StatItem } from "components/StatCard";
import { GlobalContext } from 'store/context/GlobalContext';
import { getOrdersStatus } from 'services/authService';

const OrderPaymentStatus = (props) => {
    let mounted = true;
    const { title, link } = props;
    const context = useContext(GlobalContext);
    const { setLoader } = context;
    const [ count, setCount ] = useState({
        pending: 0,
        processing: 0,
        picked: 0,
        // shipped: 0,
        // delivered: 0,
        complete: 0,
        cancel: 0,
    })

    const fetchData = useCallback(async () => {
        setLoader(true);
        let result = await getOrdersStatus();
        if(mounted){
            setCount(result.data);
            setLoader(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
        return () => mounted = false;
    }, [fetchData])

    return (
        <StatContainer>
            { title && <StatTitle>訂單狀態統計</StatTitle> }
            <StatList>
                <StatItem label="待審核" content={count.pending} themeColor="main" icon="fas fa-list" link={ link ? "/order/List?status=pending" : ""}/>
                <StatItem label="處理中" content={count.processing} themeColor="main" icon="fas fa-tasks" link={ link ? "/order/List?status=processing" : ""}/>
                <StatItem label="撿貨完成" content={count.picked} themeColor="main" icon="fas fa-box-alt" link={ link ? "/order/List?status=picked" : ""}/>
                {/* <StatItem label="已寄送" content={count.shipped} themeColor="main" icon="fas fa-envelope" link={ link ? "/order/List?status=shipped" : ""}/>
                <StatItem label="已收件" content={count.delivered} themeColor="main" icon="fas fa-envelope-open" link={ link ? "/order/List?status=delivered" : ""}/> */}
                <StatItem label="已完成" content={count.complete} themeColor="main" icon="fas fa-check" link={ link ? "/order/List?status=complete" : ""}/>
                <StatItem label="取消訂單" content={count.cancel} themeColor="main" icon="fas fa-trash" link={ link ? "/order/List?status=cancel" : ""}/>
            </StatList>
        </StatContainer>
    );
}

export default OrderPaymentStatus;