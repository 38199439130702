import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from 'store/context/GlobalContext';
import { TableContainer, Table, TableTitle } from 'components/FormTables';
import { CSVLink } from "react-csv";
import moment from 'moment/moment.js';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';

import { getBrandsList, updateBrandStatus, deleteBrand } from 'services/authService';
import SpeedDialMenu from 'components/SpeedDialMenu';
import TooltipButton from 'components/TooltipButton';
import ConfirmButton from 'components/ConfirmButton';
import Switch from '@mui/material/Switch';

export default function Brand() {
    let mounted = true;
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();
    const [ exportData, setExportData ] = useState([]);

    useEffect(() => {
        return () => mounted = false;
    }, [])

    useEffect(() => {
        if(data.data !== undefined) {
            let result = [];
            data.data.map((item, key) => {
                result.push(
                    {
                        "#": item.id,
                    }
                )
            })
            setExportData(result);
        }
    }, [data])

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'status',
            name: "上下架狀態",
            order: true,
            view: (data) => {
                return (<>
                    <Switch checked={data.status} onChange={() => handleStatus(data.id)}/>
                    { data.status ? <span className="text-success">上架</span> : <span className="text-danger">下架</span> }
                </>)
            }
        },
        {
            key: 'name',
            name: "品牌名稱",
            order: true,
        },
        {
            key: 'slug',
            name: "URL Slug",
        },
        {
            key: 'created_at',
            name: "建立時間",
            order: true,
            view: (data) => moment(data.created_at).format("YYYY-MM-DD HH:ss:mm")
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => (<>
                <div className="d-flex justify-content-center">
                    <Link to={`/ec/Brand/edit/${data.id}`} className="common-btn table-btn black"><i className="fas fa-pen mr-1"></i>修改</Link>
                    <ConfirmButton
                        className="common-btn table-btn red mgl-2"
                        onSuccess={() => handleDelete(data.id)}
                        confirmText="確定要刪除嗎？"
                        confirm={{show: true, text: '確定'}}
                        cancel={{show: true}}
                    >
                        <i className="fas fa-trash-alt mr-1"></i>刪除
                    </ConfirmButton>
                </div>
            </>)
        },
    ];

    const handleStatus = (id) => {
        setLoader(true);
        updateBrandStatus(id).then(res => {
            fetchData(params);
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const handleDelete = (id) => {
        setLoader(true);
        deleteBrand(id).then(res => {
            setSnack({open: true, text: res.messages});
            fetchData(params);
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        if(mounted){
            let result = await getBrandsList(callbackParams ? callbackParams : params);
            setData(result);
            setLoader(false);
        }
    }, [mounted]);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const actions = [
        { icon: <Link to="/ec/Brand/create" className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><AddIcon /></Link>, name: '新增品牌'},
        // { icon: <CSVLink filename="會員列表.csv" data={exportData} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><GetAppIcon /></CSVLink>, name: '匯出資料'},
    ];

    return (<>
        <TableContainer>
            <Table
                column={column}
                data={data}
                checkbox={{
                    open: false,
                    batchBar: {
                        alwaysOpen: false,
                        tools: <></>
                    },
                }}
                maxWidth="unset"
                searchbar={{date: false, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
        </TableContainer>
        <SpeedDialMenu menuList={actions} />
    </>)
}