import React, { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import ModalPortal from 'components/Portal';

const ConfirmBox = (props) => {
    const { toggle, setToggle, confirmCallback, confirmText, confirm, cancel, scroll } = props;
    const modalRef = useRef(null);
    const boxRef = useRef(null);

    useEffect(() => {
        if(toggle) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [toggle])

    const handleCancel = () => setToggle(false);

    const handleConfirm = () => {
        setToggle(false);
        confirmCallback();
    }

    const handleToggle = (e) => {
        if(!boxRef.current.contains(e.target)) {
            setToggle(false);
        }
    }

    return (
        <ModalPortal>
            <CSSTransition
                in={toggle}
                timeout={300}
                classNames="confirm-transition"
                nodeRef={modalRef}
                unmountOnExit
            >
                <div className={`modal-box scroll-${scroll ? scroll : "body"}`} ref={modalRef} onClick={handleToggle}>
                    <div className="confirm-box" ref={boxRef}>
                        <div className="confirm-box-body">{confirmText}</div>
                        <div className="confirm-box-footer">
                            { cancel && cancel.show && <button type={cancel.type} className="confirm-btn cancel" onClick={() => handleCancel()}>{ cancel.text ? cancel.text : "取消" }</button> }
                            { confirm && confirm.show && <button type={confirm.type} className="confirm-btn confirm" onClick={() => handleConfirm()}>{ confirm.text ? confirm.text : "繼續" }</button> }
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </ModalPortal>
    )
}

export default ConfirmBox;