import React from 'react';
import OrderStatus from 'pages/components/Count/OrderStatus';
import OrderPaymentStatus from 'pages/components/Count/OrderPaymentStatus';

export default function Page() {
    return (
        <div className="dashboard-page">
            {/* <OrderPaymentStatus title link /> */}
            <OrderStatus title link />
        </div>
    );
}