import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useForm } from "react-hook-form";
import { GlobalContext } from 'store/context/GlobalContext';
import { FormGroup, InputGroup, SwitchGroup } from 'components/Form/FormGroup';
import ConfirmButton from 'components/ConfirmButton';
import { createCategory, getCategorySearch } from 'services/authService';
import Switch from '@mui/material/Switch';
import useKeyboard from 'components/useKeyboard';

export default function Create() {
    let mounted = true;
    const { handleKeyDown } = useKeyboard();
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const { handleSubmit, formState: { isDirty, isValid, errors }, register, reset, getValues } = useForm({ mode: 'onChange', shouldUnregister: true });
    const [ switchToggle, setSwitchToggle ] = useState(true);
    const [ categoryList, setCategoryList ] = useState([]);

    const fetchCategoryList = useCallback(async() => {
        let result = await getCategorySearch();
        if(mounted){
            setCategoryList(result.data);
        }
    }, [mounted])

    useEffect(() => {
        fetchCategoryList();
        return () => mounted = false;
    }, [fetchCategoryList])

    const onSubmit = () => {
        let values = getValues();

        setLoader(true);
        createCategory({...values, status: switchToggle ? "1" : "0"}).then(res => {
            setSnack({open: true, text: res.messages});
            reset();
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, theme: "danger", text: err.messages.error});
            setLoader(false);
        })
    }

    const handleValidate = () => {
        let values = getValues();
        if(values.order === ""){
            setSnack({open: true, text: "請輸入分類排序"});
            return false;
        }
        if(values.name === ""){
            setSnack({open: true, text: "請輸入分類名稱"});
            return false;
        }
        if(values.slug === ""){
            setSnack({open: true, text: "請輸入URL slug"});
            return false;
        }

        return true;
    }

    return (
        <div className="pdx-3">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="上下架狀態">
                        <SwitchGroup>
                            <Switch checked={switchToggle} onChange={() => setSwitchToggle(!switchToggle)}/>
                            <span className="ml-2 font-size-14">{switchToggle ? "上架" : "下架"}</span>
                        </SwitchGroup>
                    </FormGroup>
                    <FormGroup label="排序" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="order"
                                type="number"
                                inputMode="numeric"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入分類排序"
                                defaultValue="0"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => handleKeyDown(e, { number: true })}
                                {...register("order")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="選擇上層" required>
                        <select
                            name="parent_id"
                            {...register("parent_id")}
                        >
                            <option value="">無上層</option>
                            { categoryList.map((item, index) => <option value={item.id} key={index}>{item.name}</option>)}
                        </select>
                    </FormGroup>
                    <FormGroup label="分類名稱" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="name"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入分類名稱"
                                {...register("name")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="URL slug" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="slug"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入URL slug"
                                {...register("slug")}
                            />
                        </InputGroup>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <ConfirmButton
                        className="submit-btn"
                        onValidate={handleValidate}
                        onSuccess={onSubmit}
                        confirmText="確定要送出嗎？"
                        confirm={{show: true, text: '送出'}}
                        cancel={{show: true}}
                    />
                </div>
            </form>
        </div>
    )
}