import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';
import { GlobalProvider } from 'store/context/GlobalContext';
import routes from './routes';
import PublicRoute from './Public';
import PrivateRoute from './Private';
import Loader from 'components/Loader';
import SnackBar from "components/SnackBar";
import Layout from 'layout';

const Routes = withRouter(({...props}) => {
    const history = props.history;
    const { location } = history;

    const combinePaths = (parent, child) => `${parent.replace(/\/$/, "")}/${child.replace(/^\//, "")}`;
  
    const buildPaths = (navigation, parentPath = "") =>
        navigation.map(route => {
        const path = combinePaths(parentPath, route.path);

        return {
            ...route,
            path,
            ...(route.routes && { routes: buildPaths(route.routes, path) })
        };
    });

    const setupParents = (routes, parentRoute = null) =>
        routes.map(route => {
            const withParent = {
            ...route,
            ...(parentRoute && { parent: parentRoute })
        };

        return {
            ...withParent,
            ...(withParent.routes && {
                routes: setupParents(withParent.routes, withParent)
            })
        };
    });

    const flattenRoutes = routes => routes.map(route => [route.routes ? flattenRoutes(route.routes) : [], route]).flat(Infinity);

    return (<>
        <Layout>
            <Switch location={location}>
                {
                    flattenRoutes(setupParents(buildPaths(routes))).map((route, i) => {
                        if(route.auth) {
                            return <PrivateRoute key={i} {...route} />
                        }else{
                            return <PublicRoute key={i} {...route} />
                        }
                    })
                }
            </Switch>
        </Layout>
        <SnackBar />
        <Loader />
    </>)
})

const App = () => {
    return (
        <GlobalProvider>
            <Router>
                <Routes />
            </Router>
        </GlobalProvider>
    );
}

export default App;