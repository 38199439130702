import Http from 'utils/Http';
import {
    AUTH_CHECK,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_REFRESH_TOKEN,
    AUTH_RESET_PASSWORD,
    AUTH_USER
} from 'store/action-types';

const user = {
};

const initialState = {
    isAuthenticated : false,
    isDeveloper: false,
    user
};

const Auth = (state = initialState, {type, payload = null}) => {
    switch(type){
        case AUTH_REFRESH_TOKEN:
        case AUTH_LOGIN:
            return login(state, payload);
        case AUTH_CHECK:
            return checkAuth(state);
        case AUTH_LOGOUT:
            return logout(state);
        case AUTH_RESET_PASSWORD:
            return resetPassword(state);
        case AUTH_USER:
            return authUser(state, payload)
        default:
            return state;
    }
};

const login = (state, payload) => {
    const access_token = payload.access_token;
    const refresh_token = payload.refresh_token;

    localStorage.setItem('access_token', access_token);
    refresh_token ? localStorage.setItem('refresh_token', refresh_token) : localStorage.removeItem('refresh_token');
    
    Http.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

    return {
        ...state, isAuthenticated: true,
    }
};

const checkAuth = (state) => {
    state = Object.assign({}, state, {
        isAuthenticated : !!localStorage.getItem('access_token'),
    });

    if(state.isAuthenticated){
        Http.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
    }
    return state;
};

const logout = (state) => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    return {
        ...state, isAuthenticated: false, user
    }
};

const resetPassword = (state) => {
    return {
        ...state, resetPassword: true,
    }
}

const authUser = (state, user) => {
    return {
        ...state, user: user
    }
}

export const getAuth = state => state.auth.isAuthenticated;

export default Auth;
