const navigation = [
    {
        name: '儀表板',
        url: '/dashboard',
        icon: 'fal fa-chart-line',
        main: true,
    },
    {
        name: '系統管理',
        url: '/system',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '管理員列表',
                url: '/Administrator',
                icon: 'fas fa-user-shield',
                main: true,
                children: [
                    {
                        name: '新增管理員',
                        url: '/create',
                        role: ['super', 'developer'],
                    },
                    {
                        name: '編輯管理員',
                        url: '/edit',
                        role: ['super', 'developer'],
                    },
                    {
                        name: '編輯管理員',
                        url: '/profile',
                    },
                ]
            },
        ],
    },
    {
        name: '會員管理',
        url: '/member',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '會員列表',
                url: '/List',
                icon: 'fas fa-users',
                main: true,
                children: [
                    {
                        name: '編輯會員資料',
                        url: '/edit',
                    },
                    {
                        name: '新增會員',
                        url: '/create',
                    },
                ]
            },
        ],
    },
    {
        name: '訂單管理',
        url: '/order',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '訂單列表',
                url: '/List',
                icon: 'fas fa-list-ol',
                main: true,
                params: {status: "pending"},
                children: [
                    {
                        name: '檢視訂單',
                        url: '/view',
                    },
                ]
            },
        ],
    },
    {
        name: '電子商務管理',
        url: '/ec',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '品牌列表',
                url: '/Brand',
                icon: 'fas fa-store',
                main: true,
                children: [
                    {
                        name: '新增品牌',
                        url: '/create',
                    },
                    {
                        name: '編輯品牌',
                        url: '/edit',
                    },
                ]
            },
            {
                name: '分類列表',
                url: '/Category',
                icon: 'fas fa-sitemap',
                main: true,
                children: [
                    {
                        name: '新增分類',
                        url: '/create',
                    },
                    {
                        name: '編輯分類',
                        url: '/edit',
                    },
                ]
            },
            {
                name: '商品列表',
                url: '/Product',
                icon: 'fas fa-burger-soda',
                main: true,
                children: [
                    {
                        name: '新增商品',
                        url: '/create',
                    },
                    {
                        name: '修改商品',
                        url: '/edit',
                    },
                ]
            },
        ],
    },
    {
        name: '參數管理',
        url: '/config',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '訂單設定',
                url: '/Order',
                icon: 'fas fa-list-ol',
                main: true,
            },
            {
                name: '運費設定',
                url: '/Shipping',
                icon: 'fas fa-ship',
                main: true,
            },
        ],
    },
    {
        name: '應用管理',
        url: '/application',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '活動訊息',
                url: '/Events',
                icon: 'fas fa-calendar-star',
                main: true,
                children: [
                    {
                        name: '新增活動訊息',
                        url: '/create',
                    },
                    {
                        name: '修改活動訊息',
                        url: '/edit',
                    },
                ]
            },
            {
                name: '最新公告',
                url: '/News',
                icon: 'fas fa-bullhorn',
                main: true,
                children: [
                    {
                        name: '新增最新公告',
                        url: '/create',
                    },
                    {
                        name: '修改最新公告',
                        url: '/edit',
                    },
                ]
            },
        ],
    },
]


export default navigation;