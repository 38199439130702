import { useContext } from "react";
import { GlobalContext } from "store/context/GlobalContext";
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    primary: {
        backgroundColor: "#0d6efd",
        color: "#fff",
    },
    info: {
        backgroundColor: "#0dcaf0",
        color: "#fff",
    },
    dark: {
        backgroundColor: "#212529",
        color: "#fff",
    },
    secondary: {
        backgroundColor: "#6c757d",
        color: "#fff",
    },
    success: {
        backgroundColor: "#198754",
        color: "#fff",
    },
    warning: {
        backgroundColor: "#ffc107",
        color: "#000",
    },
    danger: {
        backgroundColor: "#dc3545",
        color: "#fff",
    },
});

const SnackBar = () => {
    const classes = useStyles();
    const context = useContext(GlobalContext);
    const { snack, setSnack } = context;
    const { open, theme, text } = snack;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        
        setSnack({open: false, theme: theme ? theme : 'dark', text: text});
    };

    const action = (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    );

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={open}
            autoHideDuration={10000}
            disableWindowBlurListener={true}
            onClose={handleClose}
            TransitionComponent={Slide}
            key={text}
        >
            <SnackbarContent
                className={theme ? classes[theme] : classes.dark}
                action={action}
                message={text}
            />
        </Snackbar>
    )
}

export default SnackBar;