import React , { useState, useContext } from 'react';
import { Link, useLocation, NavLink } from 'react-router-dom';
import ButtonBase from '@mui/material/ButtonBase';
import {Collapse} from 'react-collapse';
import navigation from './navigation';
import { GlobalContext } from 'store/context/GlobalContext';

export default function Sidebar() {
    const [ menu, setMenu ] = useState(navigation);
    const context = useContext(GlobalContext);
    
    const handleChangeMenu = (key, toggle) => {
        let menus = menu;
        let item = menus[key];
        menus[key] = {...item, menuOpen: toggle};
        setMenu(menus);
    }

    return (
        <aside className={`menu-sidebar${context.menuToggle ? ' active' : ''}`}>
            <nav>
                <ul className="sidebar-list" data-widget="treeview" role="menu" data-accordion="false">
                    { menu.map((item, index) => <MenuList item={item} key={index} callback={(e) => handleChangeMenu(index, e)} />) }
                </ul>
            </nav>
        </aside>
    )
}

const MenuList = ({ item, callback }) => {
    let location = useLocation();
    let pathname = location.pathname.split('/');
    pathname.shift();
    
    const [ toggle, setToggle ] = useState(item.menuOpen);

    const handleToggle = () => {
        let result = !toggle;
        setToggle(result);
        callback(result);
    }
    
    return (
        <li className={`sidebar-item${toggle ? ' menu-open' : ''}${item.close ? ' d-none' : ''}`} >
            {
                item.children ? (
                    <div
                        className={`sidebar-label${pathname.find(path => '/'+path === item.url) ? ' active' : ''}${item.children ? ' has-children' : ''}`}
                        onClick={() => handleToggle()}
                    >
                        {item.icon && <i className={`${item.icon} sidebar-icon`}/>}
                        <div className="label">{ item.name }</div>
                    </div>
                ) : (
                    <ButtonBase className="w-100 mb-1">
                        <Link
                            to={!item.children ? item.url : '#'}
                            className={`expand-link${pathname.find(path => '/'+path === item.url) ? ' active' : ''}${item.children ? ' has-children' : ''}`}
                            onClick={() => handleToggle()}
                        >
                            {item.icon && <i className={`${item.icon} sidebar-icon`}/>}
                            <div className="label">{ item.name }</div>
                        </Link>
                    </ButtonBase>
                )
            }
            { item.children && (
                <Collapse isOpened={toggle}>
                    <ul className="expand-list">
                    { item.children.map((children, index) => (
                        <li className="expand-item" key={index}>
                            <ButtonBase className="w-100">
                                <NavLink to={item.url + children.url + (children.params ? Object.keys(children.params).map((key, index) => `${index === 0 ? '?' : '&'}${key}=${children.params[key]}`) : '') } className="expand-link" activeClassName="active">
                                    <i className={children.icon}/>
                                    <div className="label">{children.name}</div>
                                </NavLink>
                            </ButtonBase>
                        </li>
                    ))}
                    </ul>
                </Collapse>
            )}
        </li>
    )
}