import React, { useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import { FormGroup, InputGroup, SwitchGroup } from 'components/Form/FormGroup';
import { createNews } from 'services/authService';
import ConfirmButton from 'components/ConfirmButton';
import { GlobalContext } from 'store/context/GlobalContext';
import Switch from '@mui/material/Switch';

export default function Create() {
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const { handleSubmit, formState: { isDirty, isValid, errors }, register, reset, setValue, getValues } = useForm({ mode: 'onChange', shouldUnregister: true });
    const [ switchToggle, setSwitchToggle ] = useState(true);

    const onSubmit = () => {
        let values = getValues();

        setLoader(true);
        createNews({...values, status: switchToggle ? "publish" : "closed"}).then(res => {
            setSnack({open: true, text: res.messages});
            reset();
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const hanleValidate = () => {
        let values = getValues();
        if(values.title === ""){
            setSnack({open: true, text: "請輸入標題"});
            return false;
        }
        if(values.content === ""){
            setSnack({open: true, text: "請輸入內文"});
            return false;
        }
        return true;
    }

    return (<>
        <div className="pdx-3">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="上下架狀態">
                        <SwitchGroup>
                            <Switch checked={switchToggle} onChange={() => setSwitchToggle(!switchToggle)}/>
                            <span className="ml-2 font-size-14">{switchToggle ? "上架" : "下架"}</span>
                        </SwitchGroup>
                    </FormGroup>
                    <FormGroup label="標題" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="title"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入標題"
                                {...register("title")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="內文" required>
                        <InputGroup>
                            <textarea
                                tabIndex="1"
                                rows={3}
                                name="content"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入內文"
                                {...register("content")}
                            />
                        </InputGroup>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <ConfirmButton
                        className="submit-btn"
                        onValidate={hanleValidate}
                        onSuccess={onSubmit}
                        confirmText="確定要送出嗎？"
                        confirm={{show: true, text: '送出'}}
                        cancel={{show: true}}
                    />
                </div>
            </form>
        </div>
    </>)
}