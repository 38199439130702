import { useState, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ConfirmBox from 'components/ConfirmBox';
import { GlobalContext } from 'store/context/GlobalContext';
import { logout } from 'services/authService';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import ButtonBase from '@mui/material/ButtonBase';
import Skeleton from '@mui/material/Skeleton';
import LogoutIcon from '@mui/icons-material/Logout';

const UserPopper = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.Auth.user);
    const targetRef = useRef(null);
    const context = useContext(GlobalContext);
    const { setSnack, setLoader, userPopperToggle, setUserPopperToggle } = context;
    const [ confirmBoxToggle, setConfirmBoxToggle] = useState(false);

    const handleLogout = () => {
        setLoader(true);
        dispatch(logout()).then(res => {
            setLoader(false);
            setSnack({open: true, text: "登出成功"});
        }).catch(err => {
            setLoader(false);
        })
    }

    return (<>
        <ClickAwayListener onClickAway={() => setUserPopperToggle(false)}>
            <div className="h-100 mr-2">
                <ButtonBase className="h-100">
                    <div className="admin-menu" id="admin-menu" ref={targetRef} onClick={() => setUserPopperToggle(!userPopperToggle)} >
                        <i className="fas fa-user mr-2"></i>
                        <span className="name">{user.username ? user.username : <Skeleton variant="rectangular" height="20px" width="80px" style={{ borderRadius: "4px" }}/>}</span>
                        <i className="fal fa-angle-down ml-2"></i>
                    </div>
                </ButtonBase>
                <Popper
                    className="user-popper"
                    open={userPopperToggle}
                    anchorEl={targetRef.current}
                    transition
                    disablePortal
                >
                    {({ TransitionProps }) => (
                    <Grow
                        style={{ transformOrigin: '50% 0 0'}}
                        {...TransitionProps}
                        timeout={350}
                    >
                        <div className="pdx-3">
                            <div className="avatar-container">
                                <div className="popper-header">
                                    <div>{user.username ? `您好，${user.username}！` : <Skeleton variant="rectangular" height="20px" width="120px" style={{ borderRadius: "4px" }}/>}</div>
                                </div>
                                <div className="popper-body">
                                    <ul className="popper-tools-list">
                                        <li className="popper-tools-item" onClick={() => {
                                            setUserPopperToggle(false);
                                            setConfirmBoxToggle(true);
                                        }}>
                                            <div className="popper-icon"><LogoutIcon /></div>
                                            <label className="popper-label">登出</label>
                                        </li>
                                        {/* <li className="popper-tools-item" onClick={() => {
                                            setUserPopperToggle(false);
                                            setConfirmBoxToggle(true);
                                        }}>
                                            <div className="popper-icon"><i className="fas fa-sign-out fa-lg"></i></div>
                                            <label className="popper-label">登出</label>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Grow>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
        <ConfirmBox
            toggle={confirmBoxToggle}
            setToggle={setConfirmBoxToggle}
            confirmCallback={handleLogout}
            confirmText="確定登出嗎?"
            confirm={{show: true, text: '確定'}}
            cancel={{show: true, text: "取消"}}
        />
    </>)
}

export default UserPopper;