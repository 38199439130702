import React from 'react';

const PasswordShowButton = (props) => {
    const { passwordShow, setPasswordShow } = props;

    return (
        <button type="button" className="view-pass-btn" onClick={() => setPasswordShow(!passwordShow)}>
            {passwordShow ? (<i className="far fa-eye"></i>) : (<i className="far fa-eye-slash text-secondary"></i>)}
        </button>
    )
}

export default PasswordShowButton