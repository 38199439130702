import React, { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import ModalPortal from 'components/Portal';

const LightBox = (props) => {
    const { open, callback, children, className, scroll, cross, width} = props;
    const modalRef = useRef(null);
    const boxRef = useRef(null);

    //scroll：body,paper
    
    useEffect(() => {
        if(open) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [open])

    const handleToggle = (e) => {
        e.stopPropagation();
        if(!boxRef.current.contains(e.target)) {
            callback({open: false});
        }
    }

    const handleClose = (e) => {
        e.stopPropagation();
        callback({open: false});
    }

    return (
        <ModalPortal>
            <CSSTransition
                in={open}
                timeout={300}
                classNames="lightbox-transition"
                nodeRef={modalRef}
                unmountOnExit
            >
                <div className={`modal-box scroll-${scroll ? scroll : "body"} ${className ? className : ''}`} ref={modalRef} onClick={handleToggle}>
                    <div className="light-box" style={{maxWidth: width}} ref={boxRef}>
                        {children}
                        { cross && <i className="light-box-close" onClick={(e) => handleClose(e)}></i>}
                    </div>
                </div>
            </CSSTransition>
        </ModalPortal>
    )
}

export default LightBox;