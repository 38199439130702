import React from 'react';
import Div100vh from 'react-div-100vh';
import CircularProgress from '@mui/material/CircularProgress';

export default function PickUp() {
    return (
        <Div100vh>
            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                <CircularProgress sx={{color: "#000"}} color="secondary" />
                <span className="mgl-2">Loading...</span>
            </div>
        </Div100vh>
    );
}