import { applyMiddleware, createStore, compose } from 'redux';
import RootReducer from './reducers';
import ReduxThunk from 'redux-thunk';
// import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import { encryptTransform } from 'redux-persist-transform-encrypt';

const encryptor = encryptTransform({
    secretKey: 'my-super-secret-key',
    onError: function(error) {
      // Handle the error.
      console.log(error);
    }
});

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: hardSet,
    transforms: [encryptor]
}

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = createStore(
    persistedReducer,
    compose(
        applyMiddleware(ReduxThunk)
        // applyMiddleware(ReduxThunk, logger)
    )
);

persistStore(store, null, () => {
    // Something
});

export default store;
