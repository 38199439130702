import React from 'react';
import MuiDropdown from 'react-bootstrap/Dropdown';

const Dropdown = (props) => {
    const { children, direction } = props;

    return (
        <MuiDropdown autoclose="outside" drop={direction ? direction : "down"}>
            {children}
        </MuiDropdown>
    )
}

const DropdownToggle = (props) => {
    const { className, sx, children } = props;
    return (
        <MuiDropdown.Toggle className={className && className} sx={sx} >{children}</MuiDropdown.Toggle>
    )
}

const DropdownMenu = (props) => {
    const { flip, children } = props;

    return (
        <MuiDropdown.Menu flip={flip === false ? false : true} className="custom-dropdown-menu" align="end">
            { children }
        </MuiDropdown.Menu>
    )
}

export default Dropdown;
export { DropdownToggle, DropdownMenu };