import React, { useContext, useEffect } from "react";
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GlobalContext } from "store/context/GlobalContext";

const PrivateRoute = ({component: Component, ...rest}) => {
    const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);
    const context = useContext(GlobalContext);

    const pathTo = route => {
        if (!route.parent) return [{title: route.title, path: route.path}];
      
        return [...pathTo(route.parent), {title: route.title, path: route.path}];
    };

    useEffect(() => {
        let breadcrumbs = pathTo(rest);
        context.setPageState({...rest, breadcrumb: breadcrumbs});
        return () => {}
    }, [])

    return (
        <Route {...rest} render={props => (
            isAuthenticated ? (
                <Component {...rest} />
            ) : (
                <Redirect to={{
                    pathname: '/login',
                    state: {from: props.location}
                }}/>
            )
        )}/>
    );
}

export default PrivateRoute;