import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Upload from "components/Upload";
import Editor from 'components/Editor';
import { FormGroup, InputGroup, SwitchGroup } from 'components/Form/FormGroup';
import { updateEvent, getEventItem } from 'services/authService';
import ConfirmButton from 'components/ConfirmButton';
import { GlobalContext } from 'store/context/GlobalContext';
import Switch from '@mui/material/Switch';
import { setDefaultLocale } from 'react-datepicker';

export default function Edit() {
    let mounted = true;
    const { id } = useParams();
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const [ imageClear, setImageClear ] = useState(false);
    const { handleSubmit, formState: { isDirty, isValid, errors }, register, reset, setValue, getValues } = useForm({ mode: 'onChange', shouldUnregister: true });
    const [ switchToggle, setSwitchToggle ] = useState(false);
    const [ data, setData ] = useState({});

    const fetchData = useCallback(async() => {
        let result = await getEventItem(id);
        if(mounted){
            setData(result.data);
            setDefaultLocale(result.data);
            reset({
                title: result.data.title,
                content: result.data.content,
            });
            setSwitchToggle(result.data.status === "publish" ? true : false);
        }
    }, [mounted])

    useEffect(() => {
        fetchData();
        return () => mounted = false;
    }, [fetchData])

    const onSubmit = () => {
        let values = getValues();

        let result = {...values, status: switchToggle ? "publish" : "closed"};
        
        const formData = new FormData();
		Object.keys(result).map(function(key, index) {
            if(key === 'image') return formData.append(key, result.image);
			return formData.append(key, result[key]);
        });

        setLoader(true);
        updateEvent(id, formData).then(res => {
            setSnack({open: true, text: res.messages});
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const hanleValidate = () => {
        let values = getValues();
        if(values.title === ""){
            setSnack({open: true, text: "請輸入標題"});
            return false;
        }
        // if(values.desc === ""){
        //     setSnack({open: true, text: "請輸入簡介"});
        //     return false;
        // }
        if(values.content === ""){
            setSnack({open: true, text: "請輸入內文"});
            return false;
        }
        if(values.image === ""){
            setSnack({open: true, text: "請上傳縮圖"});
            return false;
        }
        return true;
    }

    return (<>
        <div className="pdx-3">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="上下架狀態">
                        <SwitchGroup>
                            <Switch checked={switchToggle} onChange={() => setSwitchToggle(!switchToggle)}/>
                            <span className="ml-2 font-size-14">{switchToggle ? "上架" : "下架"}</span>
                        </SwitchGroup>
                    </FormGroup>
                    <FormGroup label="標題" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="title"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入標題"
                                {...register("title")}
                            />
                        </InputGroup>
                    </FormGroup>
                    {/* <FormGroup label="簡介" required>
                        <InputGroup>
                            <textarea
                                tabIndex="1"
                                rows="3"
                                name="desc"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入簡介"
                                {...register("desc")}
                            />
                        </InputGroup>
                    </FormGroup> */}
                    <FormGroup label="內文" required>
                        <Editor name="content" data={getValues().content} callback={(data) => setValue("content", data)} />
                    </FormGroup>
                    <FormGroup label="上傳縮圖" required>
                        <Upload
                            id="image"
                            maxWidth="auto"
                            maxHeight="200px"
                            name="image"
                            valid={{type: ["image/jpeg", "image/png"]}}
                            register={register}
                            clearCallback={() => setValue("image", "")}
                            callback={(value) => setValue("image", value.raw)}
                            clear={imageClear}
                            defaultValue={data.image}
                        />
                        <div className="font-size-12 text-danger">圖檔長寬比建議為1:1</div>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <ConfirmButton
                        className="submit-btn"
                        onValidate={hanleValidate}
                        onSuccess={onSubmit}
                        confirmText="確定要送出嗎？"
                        confirm={{show: true, text: '送出'}}
                        cancel={{show: true}}
                    />
                </div>
            </form>
        </div>
    </>)
}