import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import PrivateLayout from './Private';
import PublicLayout from './Public';

function Layout(props) {
    const location = useLocation();
    const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);
    const { children } = props;

    if(location.pathname === "/order/pickup"){
        return(<>{children}</>);
    }
    if (isAuthenticated) {
        return <PrivateLayout {...props}>{children}</PrivateLayout>
    }
    return <PublicLayout {...props}>{children}</PublicLayout>
}
  
export default withRouter(Layout);