import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from "store/context/GlobalContext";
import HamburgerMenu from './components/HamburgerMenu';
import ButtonBase from '@mui/material/ButtonBase';
import LogoImage from 'components/LogoImage';
import UserPopper from './components/UserPopper';

const Header = () => {
    const context = useContext(GlobalContext);
    const location = useLocation();

    useEffect(() => {
        if(window.innerWidth < 992){
            context.setMenuToggle(false);
        }
    }, [location.pathname]);

    return (<>
        <header className="header">
            <nav>
                <ButtonBase className="mx-1" style={{borderRadius: '50%'}}>
                    <HamburgerMenu />
                </ButtonBase>
                <div className="d-flex align-items-center" style={{flex: "1"}}>
                    <LogoImage sx={{width: "130px"}} />
                </div>
                <UserPopper />
            </nav>
        </header>
    </>)
}

export default Header;