import React, { useState } from 'react';

const initialState = {
    pageState: {
        title: "",
        hasMain: true,
        breadcrumb: [],
        role: [],
    },
    menuToggle: window.innerWidth < 992 ? false : true,
    userPopperToggle: false,
    role: null,
    snack: {open: false, theme:'dark', text:''},
    loader: false,
    setPageState: state => {},
    setMenuToggle: state => {},
    setUserPopperToggle: state => {},
    setRole: state => {},
    setSnack: state => {},
    setLoader: state => {},
}

export const GlobalContext = React.createContext(initialState);

export const GlobalProvider = (props) => {
    const [ pageState, setPageState ] = useState(initialState.pageState);
    const [ menuToggle, setMenuToggle ] = useState(initialState.menuToggle);
    const [ userPopperToggle, setUserPopperToggle ] = useState(initialState.userPopperToggle);
    const [ role, setRole ] =  useState(initialState.role);
    const [ snack, setSnack ] = useState(initialState.snack);
    const [ loader, setLoader ] = useState(initialState.loader);

    return (
        <GlobalContext.Provider value={{
            pageState, menuToggle, userPopperToggle, role, snack, loader,
            setPageState, setMenuToggle, setUserPopperToggle, setRole, setSnack, setLoader
        }}>
            { props.children }
        </GlobalContext.Provider>
    )
}