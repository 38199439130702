import React, { useState, useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import { GlobalContext } from 'store/context/GlobalContext';
import { FormGroup, InputGroup, SwitchGroup } from 'components/Form/FormGroup';
import ConfirmButton from 'components/ConfirmButton';
import { getDeliveryConfig, updateDeliveryConfig } from 'services/authService';
import useKeyboard from 'components/useKeyboard';

export default function Shipping() {
    let mounted = true;
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const { handleSubmit, formState: { isDirty, isValid, errors }, register, reset, getValues } = useForm({ mode: 'onChange', shouldUnregister: true });
    const { handleKeyDown } = useKeyboard();

    useEffect(() => {
        const fetchDeliveryConfig = async() => {
            let result = await getDeliveryConfig();
            if(mounted){
                reset({
                    weight: result.delivery.weight,
                    dimension: result.delivery.dimension,
                    fee: result.delivery.fee,
                })
            }
        }
        fetchDeliveryConfig();
        return () => mounted = false;
    }, [])

    const onSubmit = () => {
        let values = getValues();

        setLoader(true);
        updateDeliveryConfig(values).then(res => {
            setSnack({open: true, text: res.messages});
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const handleValidate = () => {
        let values = getValues();
        if(values.weight === ""){
            setSnack({open: true, text: "請輸入重量 (lbs)"});
            return false;
        }
        if(values.dimension === ""){
            setSnack({open: true, text: "請輸入材積 (Cu in)"});
            return false;
        }
        if(values.fee === ""){
            setSnack({open: true, text: "請輸入運費"});
            return false;
        }

        return true;
    }

    return (
        <div className="pdx-3">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="重量 (lbs)" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="weight"
                                type="number"
                                inputMode="decimal"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入商品重量"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => handleKeyDown(e, { number: true, float: true })}
                                {...register("weight")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="材積 (Cu in)" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="dimension"
                                type="number"
                                inputMode="decimal"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入商品材積"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => handleKeyDown(e, { number: true, float: true })}
                                {...register("dimension")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="預設運費 ($)" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="fee"
                                type="number"
                                inputMode="decimal"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入運費"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => handleKeyDown(e, { number: true, float: true })}
                                {...register("fee")}
                            />
                        </InputGroup>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <ConfirmButton
                        className="submit-btn"
                        onValidate={handleValidate}
                        onSuccess={onSubmit}
                        confirmText="確定要送出嗎？"
                        confirm={{show: true, text: '送出'}}
                        cancel={{show: true}}
                    />
                </div>
            </form>
        </div>
    )
}