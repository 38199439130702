import React, { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from 'store/context/GlobalContext';
import WrapperContainer, { WrapperHeader, WrapperTitle, WrapperContent, WrapperBreadcrumb } from './Wrapper';
import TransitionGroupFade from 'components/Transition';

const Content = (props) => {
    const history = useHistory();
    const context = useContext(GlobalContext);
    const { children } = props;
    const wrapperEl = useRef(null);
    
    const menuClose = () => {
        window.innerWidth < 992 && context.setMenuToggle(false);
    }

    return (
        <main className={context.menuToggle ? ' active' : ''} ref={wrapperEl} onClick={menuClose}>
            <TransitionGroupFade>
                <WrapperContainer className="fit-content">
                    {
                        context.pageState.wrapperHeader !== false &&
                        <WrapperHeader className="sticky-fit-width sticky-left">
                            <div className="d-flex align-items-center">
                                {!context.pageState.hasMain && (
                                    <div className="pdr-2 mgt-3 text-dark" style={{cursor: "pointer"}} onClick={() => history.goBack()}>
                                        <i className="fas fa-chevron-left"></i>
                                        {/* <span className="ml-1">返回</span> */}
                                    </div>
                                )}
                                <WrapperTitle>{context.pageState.title}</WrapperTitle>
                            </div>
                            <WrapperBreadcrumb>{context.pageState.breadcrumb.map((item, key) => <li className={`breadcrumb-item ${key !== 0 ? "active" : ""}`} key={key}>{item.title}</li>)}</WrapperBreadcrumb>
                        </WrapperHeader>
                    }
                    <WrapperContent>
                        { children }
                    </WrapperContent>
                </WrapperContainer>
            </TransitionGroupFade>
        </main>
    );
}

export default Content;
