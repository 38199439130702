import React, { useContext, useEffect } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { GlobalContext } from "store/context/GlobalContext";


const Loader = () => {
    const context = useContext(GlobalContext);
    const { loader, setSnack, setLoader } = context;

    useEffect(() => {
        let timeout;
        
        const handleToggle = (loader) => {
            if(loader) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        }

        handleToggle(loader);

        timeout = setTimeout(() => {
            if(loader) {
                setLoader(false);
                setSnack({open: true, text: '系統出錯，請重新開啟'})
            }
        }, 35000);

        return () => {
            document.body.classList.remove('modal-open');
            clearTimeout(timeout);
        }

    }, [loader]);
    
    return (
        <Backdrop
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                color: '#fff',
                backgroundColor: 'rgba(0,0,0,0.55)'
            }}
            open={loader}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default Loader;