import React, { useContext } from 'react'
import { GlobalContext } from 'store/context/GlobalContext';

const HamburgerMenu = () => {
    const context = useContext(GlobalContext);

    return (
        <div className={`hamburger-box ${context.menuToggle ? "active" : ""}`} onClick={() => context.setMenuToggle(!context.menuToggle)}>
            <div className="hamburger-menu">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}

export default HamburgerMenu;
