import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { GlobalContext } from 'store/context/GlobalContext';
import { TableContainer, Table, OnePageShow, SearchBar } from 'components/FormTables';
import Dropdown, { DropdownToggle, DropdownMenu } from 'components/Dropdown';
import moment from 'moment/moment.js';
import { getOrdersList, updateOrderStatus, updateOrderPaymentStatus, updateOrderBatchStatus, updateOrderBatchPaymentStatus, exportPickUp, exportOrders } from 'services/authService';
import ConfirmButton from 'components/ConfirmButton';

export default function List() {
    let mounted = true;
    const location = useLocation();
    const history = useHistory();
    const paramsStatus = new URLSearchParams(location.search).get('status');
    const paramsPaymentStatus = new URLSearchParams(location.search).get('paymentStatus');
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState({});
    const [ checkIds, setCheckIds ] = useState([]);

    useEffect(() => {
        return () => mounted = false;
    }, [])

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'order_number',
            name: "訂單編號",
            order: true,
            view: (data) => <Link className="d-block text-decoration-underline text-dark" to={`/order/List/view/${data.id}`}>#{data.order_number}</Link>
        },
        {
            key: 'status',
            name: "訂單狀態",
            view: (data) => {
                switch (data.status){
                    case "pending": return "待審核";
                    case "processing": return "處理中";
                    case "picked": return "撿貨完成";
                    // case "shipped": return "已寄送";
                    // case "delivered": return "已收件";
                    case "complete": return <span className="text-success">已完成</span>;
                    case "cancel": return "取消訂單";
                    default: return "-";
                }
            }
        },
        // {
        //     key: 'payment_status',
        //     name: "繳款狀態",
        //     view: (data) => {
        //         switch (data.payment_status){
        //             case "unpaid": return <span className="text-danger">未繳款</span>;
        //             case "paid": return <span className="text-success">已繳款</span>;
        //             default: return "-";
        //         }
        //     }
        // },
        {
            key: 'user',
            name: "會員帳號",
            view: (data) => data.user.username
        },
        {
            key: 'user',
            name: "會員名稱",
            view: (data) => data.user.name
        },
        {
            key: 'shipping_method',
            name: "運送方式",
            view: (data) => {
                switch (data.shipping_method){
                    case "delivery": return "寄送";
                    case "pickup": return "自取";
                    default: return "-";
                }
            }
        },
        {
            key: 'subtotal',
            name: "小計",
            view: (data) => `$${data.subtotal}`
        },
        {
            key: 'delivery_fee',
            name: "運費",
            view: (data) => `$${data.delivery_fee}`
        },
        {
            key: 'discount',
            name: "折扣",
            view: (data) => `$${data.discount}`
        },
        {
            key: 'surcharge',
            name: "附加費用",
            view: (data) => `$${data.surcharge}`
        },
        {
            key: 'total_price',
            name: "總計金額",
            view: (data) => `$${data.total_price}`
        },
        {
            key: 'created_at',
            name: "訂單成立時間",
            order: true,
            view: (data) => moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => (<>
                <Dropdown direction="down">
                    <DropdownToggle className="btn btn-secondary btn-sm">操作</DropdownToggle>
                    <DropdownMenu flip={false}>
                        {/* { handlePaymentStatus(data) } */}
                        { handleStatus(data) }
                    </DropdownMenu>
                </Dropdown>
            </>)
        },
    ];

    const handlePaymentStatus = (data) => {
        switch (data.payment_status) {
            case "unpaid": return (<>
                {
                    data.status !== "cancel" &&
                    <ConfirmButton
                        className="dropdown-item"
                        onSuccess={() => handleUpdatePaymentStatus(data.id, "paid")}
                        confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「已繳款」嗎？`}
                        confirm={{show: true, text: '確定'}}
                        cancel={{show: true}}
                    >
                        <i className="fas fa-money-check"></i>設為已繳款
                    </ConfirmButton>
                }
            </>);
            case "paid": return (<>
                {
                    data.status !== "cancel" &&
                    <ConfirmButton
                        className="dropdown-item"
                        onSuccess={() => handleUpdatePaymentStatus(data.id, "unpaid")}
                        confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「未繳款」嗎？`}
                        confirm={{show: true, text: '確定'}}
                        cancel={{show: true}}
                    >
                        <i className="fas fa-money-check"></i>設為未繳款
                    </ConfirmButton>
                }
            </>);
            default: return (<></>);
        }
    }

    const handleUpdatePaymentStatus = (id, status) => {
        setLoader(true);
        updateOrderPaymentStatus(id, { status: status }).then(res => {
            fetchData(params);
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const handleStatus = (data) => {
        switch (data.status) {
            case "pending": return (<>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(data.id, "processing")}
                    confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「處理中」嗎？`}
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-angle-double-right"></i>狀態設為處理中
                </ConfirmButton>
                <Link className="dropdown-item" to={`/order/List/view/${data.id}`}><i className="fas fa-pen"></i>修改訂單</Link>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(data.id, "cancel")}
                    confirmText="確定要取消訂單嗎？"
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-trash-alt"></i>取消訂單
                </ConfirmButton>
            </>);
            case "processing": return (<>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(data.id, "picked")}
                    confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「撿貨完成」嗎？`}
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-angle-double-right"></i>狀態設為撿貨完成
                </ConfirmButton>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(data.id, "pending")}
                    confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「待審核」嗎？`}
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-angle-double-left"></i>狀態返回待審核
                </ConfirmButton>
                <Link className="dropdown-item" to={`/order/List/view/${data.id}`}><i className="fas fa-pen"></i>修改訂單</Link>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(data.id, "cancel")}
                    confirmText="確定要取消訂單嗎？"
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-trash-alt"></i>取消訂單
                </ConfirmButton>
            </>);
            case "picked": return (<>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(data.id, "complete")}
                    confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「已完成」嗎？`}
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-angle-double-right"></i>狀態設為已完成
                </ConfirmButton>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(data.id, "processing")}
                    confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「處理中」嗎？`}
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-angle-double-left"></i>狀態返回處理中
                </ConfirmButton>
                <Link className="dropdown-item" to={`/order/List/view/${data.id}`}><i className="fas fa-list-ol"></i>檢視訂單</Link>
            </>);
            // case "shipped": return (<>
            //     <ConfirmButton
            //         className="dropdown-item"
            //         onSuccess={() => handleUpdateStatus(data.id, "delivered")}
            //         confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「已收件」嗎？`}
            //         confirm={{show: true, text: '確定'}}
            //         cancel={{show: true}}
            //     >
            //         <i className="fas fa-angle-double-right"></i>狀態設為已收件
            //     </ConfirmButton>
            //     <ConfirmButton
            //         className="dropdown-item"
            //         onSuccess={() => handleUpdateStatus(data.id, "picked")}
            //         confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「檢貨完成」嗎？`}
            //         confirm={{show: true, text: '確定'}}
            //         cancel={{show: true}}
            //     >
            //         <i className="fas fa-angle-double-left"></i>狀態返回檢貨完成
            //     </ConfirmButton>
            //     <Link className="dropdown-item" to={`/order/List/view/${data.id}`}><i className="fas fa-list-ol"></i>檢視訂單</Link>
            // </>);
            // case "delivered": return (<>
            //     <ConfirmButton
            //         className="dropdown-item"
            //         onSuccess={() => handleUpdateStatus(data.id, "complete")}
            //         confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「已完成」嗎？`}
            //         confirm={{show: true, text: '確定'}}
            //         cancel={{show: true}}
            //     >
            //         <i className="fas fa-angle-double-right"></i>狀態設為已完成
            //     </ConfirmButton>
            //     <ConfirmButton
            //         className="dropdown-item"
            //         onSuccess={() => handleUpdateStatus(data.id, "shipped")}
            //         confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「已寄送」嗎？`}
            //         confirm={{show: true, text: '確定'}}
            //         cancel={{show: true}}
            //     >
            //         <i className="fas fa-angle-double-left"></i>狀態返回已寄送
            //     </ConfirmButton>
            //     <Link className="dropdown-item" to={`/order/List/view/${data.id}`}><i className="fas fa-list-ol"></i>檢視訂單</Link>
            // </>);
            case "complete": return (<>
                <ConfirmButton
                    className="dropdown-item"
                    onSuccess={() => handleUpdateStatus(data.id, "picked")}
                    confirmText={`確定要把訂單 #${data.order_number} 變更訂單狀態為「撿貨完成」嗎？`}
                    confirm={{show: true, text: '確定'}}
                    cancel={{show: true}}
                >
                    <i className="fas fa-angle-double-left"></i>狀態返回撿貨完成
                </ConfirmButton>
                <Link className="dropdown-item" to={`/order/List/view/${data.id}`}><i className="fas fa-list-ol"></i>檢視訂單</Link>
            </>);
            case "cancel": return (<>
                <Link className="dropdown-item" to={`/order/List/view/${data.id}`}><i className="fas fa-list-ol"></i>檢視訂單</Link>
            </>);
            default: return (<></>);
        }
    }

    const handleUpdateStatus = (id, status) => {
        setLoader(true);
        updateOrderStatus(id, { status: status }).then(res => {
            fetchData(params);
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        if(mounted){
            let result = await getOrdersList(callbackParams ? callbackParams : params);
            setData(result);
            setLoader(false);
        }
    }, [mounted]);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const handleCheckbox = (array, key) => {
        let result = [];
        array.map((index) => {
            result.push(data.data[index][key]);
        })
        setCheckIds(result);
    }

    const handleExportPickUp = () => {
        let safariWindow = window.open("", "出貨單", 'height=500px,width=1280px');
        safariWindow.opener = null;
        safariWindow.location.href = "/order/pickup";
        exportPickUp({...params, ids: checkIds}).then(res => {
            setTimeout(() => {
                safariWindow.document.body.innerHTML = res;
                safariWindow.document.close();
                safariWindow.focus();
            }, 100)
        })
    }

    const handleExportOrders = () => {
        exportOrders({...params, ids: checkIds}).then(response => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `orders${moment().format("YYYY-MM-DD")}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
    }

    const handleBatchStatus = (status) => {
        switch (status) {
            case "pending": return (<>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchPaymentStatus("paid")}><i className="fas fa-money-check"></i>設為已繳款</button>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchPaymentStatus("unpaid")}><i className="fas fa-money-check"></i>設為未繳款</button>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("processing")}><i className="fas fa-angle-double-right"></i>狀態設為處理中</button>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("cancel")}><i className="fas fa-trash-alt"></i>取消訂單</button>
            </>);
            case "processing": return (<>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchPaymentStatus("paid")}><i className="fas fa-money-check"></i>設為已繳款</button>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchPaymentStatus("unpaid")}><i className="fas fa-money-check"></i>設為未繳款</button>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("picked")}><i className="fas fa-angle-double-right"></i>狀態設為撿貨完成</button>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("pending")}><i className="fas fa-angle-double-left"></i>狀態返回待審核</button>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("cancel")}><i className="fas fa-trash-alt"></i>取消訂單</button>
            </>);
            case "picked": return (<>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchPaymentStatus("paid")}><i className="fas fa-money-check"></i>設為已繳款</button>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchPaymentStatus("unpaid")}><i className="fas fa-money-check"></i>設為未繳款</button>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("complete")}><i className="fas fa-angle-double-right"></i>狀態設為已完成</button>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("processing")}><i className="fas fa-angle-double-left"></i>狀態返回處理中</button>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("cancel")}><i className="fas fa-trash-alt"></i>取消訂單</button>
            </>);
            // case "shipped": return (<>
            //     <button className="dropdown-item" type="button" onClick={() => handleOrderBatchPaymentStatus("paid")}><i className="fas fa-money-check"></i>設為已繳款</button>
            //     <button className="dropdown-item" type="button" onClick={() => handleOrderBatchPaymentStatus("unpaid")}><i className="fas fa-money-check"></i>設為未繳款</button>
            //     <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("delivered")}><i className="fas fa-angle-double-right"></i>狀態設為已收件</button>
            //     <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("picked")}><i className="fas fa-angle-double-left"></i>狀態返回撿貨完成</button>
            //     <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("cancel")}><i className="fas fa-trash-alt"></i>取消訂單</button>
            // </>);
            // case "delivered": return (<>
            //     <button className="dropdown-item" type="button" onClick={() => handleOrderBatchPaymentStatus("paid")}><i className="fas fa-money-check"></i>設為已繳款</button>
            //     <button className="dropdown-item" type="button" onClick={() => handleOrderBatchPaymentStatus("unpaid")}><i className="fas fa-money-check"></i>設為未繳款</button>
            //     <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("complete")}><i className="fas fa-angle-double-right"></i>狀態設為已完成</button>
            //     <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("shipped")}><i className="fas fa-angle-double-left"></i>狀態返回已寄送</button>
            //     <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("cancel")}><i className="fas fa-trash-alt"></i>取消訂單</button>
            // </>);
            case "complete": return (<>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchPaymentStatus("paid")}><i className="fas fa-money-check"></i>設為已繳款</button>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchPaymentStatus("unpaid")}><i className="fas fa-money-check"></i>設為未繳款</button>
                <button className="dropdown-item" type="button" onClick={() => handleOrderBatchStatus("picked")}><i className="fas fa-angle-double-left"></i>狀態返回撿貨完成</button>
            </>);
            case "cancel": return (<></>);
            default: return (<></>);
        }
    }

    const handleOrderBatchStatus = (status) => {
        setLoader(true);
        updateOrderBatchStatus({ids: checkIds, status: status}).then(res => {
            fetchData(params);
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const handleOrderBatchPaymentStatus = (status) => {
        setLoader(true);
        updateOrderBatchPaymentStatus({ids: checkIds, status: status}).then(res => {
            fetchData(params);
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    return (<>
        <TableContainer>
            <div className="table-head sticky-fit-width sticky-left">
                <SearchBar searchCallback={(searchResult) => handleCallback({...params, search: searchResult, page: 1})} searchbar={{date: true, keyword: true}} />
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <div className="d-flex mgr-2">
                        <select
                            className="form-select font-size-14 mgb-4"
                            value={params.status}
                            onChange={(e) => {
                                setCheckIds([]);
                                history.push(`/order/List?status=${e.target.value}&paymentStatus=${paramsPaymentStatus ? paramsPaymentStatus : ""}`);
                                handleCallback({...params, status: e.target.value, page: 1});
                            }}
                        >
                            <option value="default" disabled>狀態</option>
                            <option value="">所有訂單狀態</option>
                            <option value="pending">待審核</option>
                            <option value="processing">處理中</option>
                            <option value="picked">撿貨完成</option>
                            {/* <option value="shipped">已寄送</option>
                            <option value="delivered">已收件</option> */}
                            <option value="complete">已完成</option>
                            <option value="cancel">取消訂單</option>
                        </select>
                        {/* <select
                            className="form-select font-size-14 mgb-4 mgl-2"
                            value={params.payment_status}
                            onChange={(e) => {
                                setCheckIds([]);
                                history.push(`/order/List?status=${paramsStatus ? paramsStatus : ""}&paymentStatus=${e.target.value}`);
                                handleCallback({...params, payment_status: e.target.value, page: 1})
                            }}
                        >
                            <option value="default" disabled>繳款狀態</option>
                            <option value="">所有繳款狀態</option>
                            <option value="unpaid">未繳款</option>
                            <option value="paid">已繳款</option>
                        </select> */}
                    </div>
                    <OnePageShow perPageCallback={(page) => handleCallback({...params, countPerPage: page, page: 1})} defaultPerPage={10} />
                </div>
            </div>
            <Table
                column={column}
                data={data}
                countPerPage={params.countPerPage}
                checkbox={{
                    open: true,
                    batchBar: {
                        alwaysOpen: true,
                        tools: <>
                            <Dropdown direction="down">
                                <DropdownToggle className="mgy-1 btn btn-success btn-sm" >匯出</DropdownToggle>
                                <DropdownMenu>
                                    <button className="dropdown-item" type="button" onClick={() => handleExportOrders()}><i className="fas fa-file-export"></i>匯出訂單列表</button>
                                    <button className="dropdown-item" type="button" onClick={() => handleExportPickUp()}><i className="fas fa-print"></i>列印出貨單</button>
                                </DropdownMenu>
                            </Dropdown>
                            {
                                (params.status !== "" && params.status !== "cancel") && (
                                    <Dropdown direction="down">
                                        <DropdownToggle className="mgy-1 btn btn-danger btn-sm mgl-2">功能</DropdownToggle>
                                        <DropdownMenu>
                                            { handleBatchStatus(params.status) }
                                        </DropdownMenu>
                                    </Dropdown>
                                )
                            }
                        </>
                    },
                    reset: (checkIds.length > 0) ? false : true,
                    callback: (array) => handleCheckbox(array, "id")
                }}
                maxWidth="unset"
                callback={(callbackParams) => handleCallback({...params, ...callbackParams, status: paramsStatus ? paramsStatus : "", payment_status: paramsPaymentStatus ? paramsPaymentStatus : ""})}
            />
        </TableContainer>
    </>)
}