import React, { useState, useEffect, useRef } from 'react';
import PhotoModal from 'components/PhotoModal';

const Upload = (props) => {
    const { id, index, name, defaultValue, className, boxPadding, width, height, maxWidth, maxHeight, valid, showPreivew, onChange, callback, clear, clearCallback } = props;
    const [ image, setImage ] = useState({preview: "", raw: "", width: 0, height: 0});
    const [ errors, setErrors ] = useState(false);
    const [ errorText, setErrorText ] = useState("");
    const clearRef = useRef();

    useEffect(() => {
        let img = new Image();
        img.src = defaultValue;
        img.onload = () => {
            setImage(image => ({...image, preview: img.src, width: img.width, height: img.height}));
        }
    },[defaultValue])

    useEffect(() => {
        if(clear) handleClear();
    }, [clear])

    const uploadFile = () => {
        if(image.preview === ""){
            let uploadFileBtn = document.getElementById(id);
            uploadFileBtn.click();
        }
    }

    const handleChange = (e) => {
        if(!e.target.files[0]) handleClear();

        onChange && onChange(e);
        
        let img = new Image();
        img.src = URL.createObjectURL(e.target.files[0]);
        img.onload = () => {
            let result = {
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
                width: img.width,
                height: img.height,
            }
            setImage(result);
            let error = handleError(result);
            setErrors(error);
            callback && callback(result);
        }
    }

    const handleClear = (e) => {
        e && e.stopPropagation();
        setImage({preview: "", raw: "", width: 0, height: 0});
        setErrorText("");
        setErrors(false);
        clearCallback && clearCallback();
    }

    const handleError = (image) => {
        let text = `圖檔 「 ${image.raw.name} 」 `;
        let error = false;
        if(image.preview && valid) {
            if(valid.width > image.width) {
                text += `, 寬度必須至少為 ${valid.width} 像素`;
                error = true;
            }
            if(valid.height > image.height){
                text += ` , 高度必須至少為 ${valid.height} 像素`;
                error = true;
            }
            if(valid.type){
                if(valid.type.findIndex(e => e === image.raw.type) === -1){
                    text += ` , 檔案格式必須為 ${valid.type.map((item) => `${item} `)}`;
                    error = true;
                }
            }
            setErrorText(text);
        }
        return error;
    }

    return (
        <div className={`upload-container ${className ? className : ''}`} key={index}>
            <div className={`upload-images ${image.preview && showPreivew !== false ? 'hasImage' : ''}`} style={{padding: boxPadding}} onClick={() => uploadFile()}>
                {
                    (!showPreivew || showPreivew !== false) && image.preview ? (<>
                        <PhotoModal
                            src={ image.preview }
                            style={{
                                width: width ? width : "",
                                maxWidth: maxWidth ? maxWidth : "",
                                height: height ? height : "",
                                maxHeight: maxHeight ? maxHeight : "",
                                opacity: errors ? "0.5" : "1",
                            }}
                        />
                        <div className="py-1">
                            <div className="name">{image.raw.name}</div>
                            <div className="size">( {image.width} x {image.height} )</div>
                        </div>
                    </>) : (
                        <i className="fal fa-plus"></i>
                    )
                }
                <input
                    key={index && index}
                    id={id}
                    type="file"
                    name={name ? name : `file`}
                    className="d-none"
                    accept="image/gif, image/jpeg, image/png"
                    onClick={(e) => e.target.value= null}
                    onChange={(e) => handleChange(e)}
                />
                { image.preview && <div className="clear-image" ref={clearRef} onClick={(e) => handleClear(e)}></div> }
            </div>
            { errors && <div className="error-alert">{errorText}</div> }
        </div>
    )
}

// const Uploads = ({ index, id, name, boxPadding, className, width, height, maxWidth, maxHeight, valid, showPreivew, register, defaultValue, callback, clearCallback, clear }) => {
//     const [ image, setImage ] = useState({preview: "", raw: "", width: 0, height: 0});
//     const [ errors, setErrors ] = useState(false);
//     const [ errorText, setErrorText ] = useState("");

//     if(showPreivew === undefined) showPreivew = true;

//     useEffect(() => {
//         if(clear){
//             setImage({preview: "", raw: ""});
//             setErrorText("");
//             setErrors(false);
//             clearCallback && clearCallback();
//         }
//     }, [clear])

//     const handleChange = (e) => {
//         if(e.target.files[0]) {
//             let img = new Image();
//             img.src = URL.createObjectURL(e.target.files[0]);
//             img.onload = () => {
//                 let result = {
//                     preview: URL.createObjectURL(e.target.files[0]),
//                     raw: e.target.files[0],
//                     width: img.width,
//                     height: img.height,
//                 }
//                 setImage(result);
//                 let error = handleError(result);
//                 setErrors(error);
//                 callback && callback(result);
//             }
//         } else {
//             setImage({preview: "", raw: ""});
//             setErrorText("");
//             setErrors(false);
//             clearCallback && clearCallback();
//         }
//     }

//     useEffect(() => {
//         let img = new Image();
//         img.src = defaultValue;
//         img.onload = () => {
//             setImage(image => ({...image, preview: img.src, width: img.width, height: img.height}));
//         }
//     },[defaultValue])

//     const uploadFile = () => {
//         if(image.preview === ""){
//             let uploadFileBtn = document.getElementById(id);
//             uploadFileBtn.click();
//         }
//         // if(image.raw === "") {
//         //     let uploadFileBtn = document.getElementById(id);
//         //     uploadFileBtn.click();
//         // }
//     }

//     const handleClear = (e) => {
//         e.stopPropagation()
//         setImage({preview: "", raw: ""});
//         setErrorText("");
//         setErrors(false);
//         clearCallback && clearCallback();
//     }

//     const handleError = (image) => {
//         let text = `圖檔 「 ${image.raw.name} 」 `;
//         let error = false;
//         if(image.preview && valid) {
//             if(valid.width > image.width) {
//                 text += `, 寬度必須至少為 ${valid.width} 像素`;
//                 error = true;
//             }
//             if(valid.height > image.height){
//                 text += ` , 高度必須至少為 ${valid.height} 像素`;
//                 error = true;
//             }
//             if(valid.type){
//                 if(valid.type.findIndex(e => e === image.raw.type) === -1){
//                     text += ` , 檔案格式必須為 ${valid.type.map((item) => `${item} `)}`;
//                     error = true;
//                 }
//             }
//             setErrorText(text);
//         }
//         return error;
//     }

//     return (
//         <div className={`upload-container ${className ? className : ''}`} key={index}>
//             <div className={`upload-images ${image.preview && showPreivew === true ? 'hasImage' : ''}`} style={{padding: boxPadding}} onClick={() => uploadFile()}>
//                 {
//                     showPreivew && image.preview ? (<>
//                         <PhotoModal
//                             src={ image.preview }
//                             style={{
//                                 width: width ? width : "",
//                                 maxWidth: maxWidth ? maxWidth : "",
//                                 height: height ? height : "",
//                                 maxHeight: maxHeight ? maxHeight : "",
//                                 opacity: errors ? "0.5" : "1",
//                             }}
//                         />
//                         <div className="py-1">
//                             <div className="name">{image.raw.name}</div>
//                             <div className="size">( {image.width} x {image.height} )</div>
//                         </div>
//                     </>) : (
//                         <i className="fal fa-plus"></i>
//                     )
//                 }
//                 <input
//                     key={index && index}
//                     type="file"
//                     name={name ? name : `file`}
//                     className="d-none"
//                     id={id} accept="image/gif, image/jpeg, image/png"
//                     {...register}
//                     onInput={(e) => handleChange(e)}
//                 />
//                 { image.preview && <div className="clear-image" onClick={(e) => handleClear(e)}></div> }
//             </div>
//             { errors && <div className="error-alert">{errorText}</div> }
//         </div>
//     );
// }

export default Upload;