import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import Auth from './Auth';
import persistStore from './persistStore';
import localesReducer from './localesReducer';

const RootReducer = combineReducers({
    Auth: Auth,
    intl: intlReducer,
    locales: localesReducer,
    persistStore,
});

export default RootReducer;