import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { GlobalContext } from 'store/context/GlobalContext';
import { FormGroup, InputGroup } from 'components/Form/FormGroup';
import ConfirmButton from 'components/ConfirmButton';
import { getBrandItem, updateBrand } from 'services/authService';
import Switch from '@mui/material/Switch';

export default function Edit() {
    let mounted = true;
    const { id } = useParams();
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const { handleSubmit, formState: { isDirty, isValid, errors }, register, reset, getValues, setValue } = useForm({shouldUnregister: true });
    const [ data, setData ] = useState({});
    const [ switchToggle, setSwitchToggle ] = useState(false);

    const fetchData = useCallback(async() => {
        let result = await getBrandItem(id);
        if(mounted){
            setData(result.data);
            reset({
                name: result.data.name ? result.data.name : "",
                slug: result.data.slug ? result.data.slug: "",
            });
            setSwitchToggle(result.data.status);
        }
    }, [])

    useEffect(() => {
        fetchData();
        return () => mounted = false;
    }, [fetchData])

    const onSubmit = () => {
        let values = getValues();
        
        setLoader(true);
        updateBrand(id, {...values, status: switchToggle ? "1" : "0"}).then(res => {
            setSnack({open: true, text: res.messages});
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const handleValidate = () => {
        let values = getValues();

        if(values.name === ""){
            setSnack({open: true, text: "請輸入品牌名稱"});
            return false;
        }
        if(values.slug === ""){
            setSnack({open: true, text: "請輸入URL slug"});
            return false;
        }

        return true;
    }

    return (
        <div className="pdx-3">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="上下架狀態">
                        <div className="switch-group">
                            <Switch checked={switchToggle} onChange={() => setSwitchToggle(!switchToggle)}/>
                            <span className="ml-2 font-size-14">{switchToggle ? "上架" : "下架"}</span>
                        </div>
                    </FormGroup>
                    <FormGroup label="品牌名稱" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="name"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入品牌名稱"
                                {...register("name")}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="URL slug" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="slug"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入URL slug"
                                {...register("slug")}
                            />
                        </InputGroup>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <ConfirmButton
                        className="submit-btn"
                        onValidate={handleValidate}
                        onSuccess={onSubmit}
                        confirmText="確定要送出嗎？"
                        confirm={{show: true, text: '送出'}}
                        cancel={{show: true}}
                    />
                </div>
            </form>
        </div>
    )
}