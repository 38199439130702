import React, { useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { useCountUp } from 'react-countup';
import { Link } from 'react-router-dom';

const StatContainer = ({children}) => {
    return (
        <div className="stat-container">
            { children }
        </div>
    )
}

const StatTitle = ({children}) => {
    return (
        <h5>{ children }</h5>
    )
}

const StatList = ({ children }) => {
    return (
        <ul className="stat-list row" style={{margin: "0 -7.5px"}}>
            { children }
        </ul>
    )
}

const StatItem = (props) => {
    const { label, content, icon, themeColor, link } = props;
    // const { countUp, update } = useCountUp({
    //     start: 0,
    //     end: 0,
    //     delay: 0,
    //     duration: 2,
    //     separator: ",",
    //     useEasing: true,
    // });

    // useEffect(() => {
    //     let timeout;
    //     timeout = setTimeout(() => {
    //         update(content);
    //     }, delay)
    //     return () => {
    //         clearTimeout(timeout);
    //     }
    // }, [content])

    return (
        <li className="stat-item col-xl-3 col-md-4 col-6">
            <div className={`stat-box theme-${themeColor}`}>
                <div className="info">
                    <div className="content">{content}</div>
                    <label>{label}</label>
                    <i className={`stat-icon ${icon}`}></i>
                </div>
                { link && <Link to={link} className="stat-link">查看更多<i className="fas fa-arrow-circle-right ml-1"></i></Link> }
            </div>
        </li>
    )
}


export { StatContainer, StatTitle, StatList, StatItem };
