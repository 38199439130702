import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import Div100vh from 'react-div-100vh';
import TextField from '@mui/material/TextField';
import { login } from 'services/authService';
import { GlobalContext } from 'store/context/GlobalContext';
import LogoImage from 'components/LogoImage';
import Spinner from 'react-bootstrap/Spinner';

export default function Page() {
    const dispatch = useDispatch();
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    // const { register, handleSubmit, formState } = useForm({ mode: "onChange" });
    const [ buttonSubmit, setButtonSubmit ] = useState(false);
    const [ passwordShow, setPasswordShow ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const { handleSubmit, formState: { isDirty, isValid, errors }, register, reset, getValues } = useForm({ mode: 'onChange', shouldUnregister: true });

    const onSubmit = (data) => {
        if(data.password.length < 6){
            setSnack({open: true, text: "密碼最少為6位"});
            return;
        }
        setLoading(true);
        dispatch(login(data)).then(res => {
            setSnack({open: true, text: '登入成功'});
            // setLoading(false);
        }).catch(err => {
            setSnack({open: true, theme: 'danger', text: err.messages.error});
            setLoading(false);
        })
    }
    
    return (
        <Div100vh>
            <div className="login-page">
                <div className="logo-container">
                    <LogoImage theme="white" sx={{width: "250px"}} />
                    <div className="font-size-16 text-white mgt-3 mgb-6">
                        <div>後台管理系統</div>
                        <div>Admin system</div>
                    </div>
                    <div className="login-box">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="input-group mgb-3">
                                <TextField
                                    name="username"
                                    className="w-100"
                                    label="帳號"
                                    variant="outlined"
                                    autoComplete="new-username"
                                    spellCheck="false"
                                    {...register("username", {
                                        required: {
                                            value: true,
                                        },
                                    })}
                                />
                            </div>
                            <div className="input-group mgb-6">
                                <TextField
                                    name="password"
                                    className="w-100"
                                    type={passwordShow ? "text" : "password"}
                                    label="密碼"
                                    variant="outlined"
                                    autoComplete="new-password"
                                    spellCheck="false"
                                    {...register("password", {
                                        required: {
                                            value: true,
                                        },
                                    })}
                                />
                                <div className="input-text">
                                    <button type="button" className="view-pass-btn" onClick={() => setPasswordShow(!passwordShow)}>
                                        {passwordShow ? (<i className="far fa-eye"></i>) : (<i className="far fa-eye-slash text-secondary"></i>)}
                                    </button>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="login-btn"
                                disabled={(!isDirty || !isValid) || loading}
                            >
                                登入
                                { loading && <Spinner className="mgl-2" animation="border" size="sm" style={{borderWidth: "0.15em"}} />}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Div100vh>
    )
}
