import React, { useState, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { TableContainer, Table, TableTitle } from 'components/FormTables';
import AddIcon from '@mui/icons-material/Add';
import ConfirmButton from 'components/ConfirmButton';
import { getNewsList, deleteNews, updateNewsStatus } from 'services/authService';
import moment from "moment/moment.js";
import SpeedDialMenu from 'components/SpeedDialMenu';
import { GlobalContext } from 'store/context/GlobalContext';
import Switch from '@mui/material/Switch';

export default function News() {
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'status',
            name: "上下架狀態",
            order: true,
            view: (data) => {
                return (<>
                    <Switch checked={data.status === "publish"} onChange={() => handleStatus(data.id)}/>
                    { data.status === "publish" ? <span className="text-success">上架</span> : <span className="text-danger">下架</span> }
                </>)
            }
        },
        {
            key: 'title',
            name: "標題",
        },
        {
            key: 'content',
            name: "內文",
            view: (data) => <div className="text-break-1" style={{width: "500px", margin: "0 auto"}}>{data.content}</div>
        },
        {
            key: 'created_at',
            name: "建立時間",
            order: true,
            view: (data) => moment(data.created_at).format("YYYY-MM-DD HH:mm:ss")
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => (<>
                <div className="d-flex justify-content-center">
                    <Link to={`/application/News/edit/${data.id}`} className="common-btn table-btn black"><i className="fas fa-pen mr-1"></i>修改消息</Link>
                    <ConfirmButton
                        className="common-btn table-btn red ml-2"
                        onSuccess={() => handleDelete(data.id)}
                        confirmText="確定要刪除嗎？"
                        confirm={{show: true, text: '刪除'}}
                        cancel={{show: true}}
                    >
                        <i className="fas fa-trash mr-1"></i>刪除
                    </ConfirmButton>
                </div>
            </>)
        },
    ];

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        let result = await getNewsList(callbackParams ? callbackParams : params);
        setData(result);
        setLoader(false);
    }, []);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const handleStatus = (id) => {
        setLoader(true);
        updateNewsStatus(id).then(res => {
            fetchData(params);
            setLoader(false);
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const handleDelete = useCallback((id) => {
        setLoader(true);
        deleteNews(id).then(res => {
            setSnack({open: true, text: res.messages});
            fetchData();
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }, []);

    const actions = [
        { icon: <Link to={`/application/News/create`} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><AddIcon /></Link>, name: '新增最新消息'},
    ];

    return (<>
        <TableContainer>
            <Table
                column={column}
                data={data}
                checkbox={{
                    open: false,
                    batchBar: {
                        alwaysOpen: false,
                        tools: <></>
                    },
                }}
                searchbar={{date: true, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
        </TableContainer>
        <SpeedDialMenu menuList={actions} />
    </>)
}