import React from 'react';

const TableContainer = ({children, maxWidth}) => {
    return (
        <div className="table-container" style={{maxWidth: maxWidth ? maxWidth : "unset"}}>
            {children}
        </div>
    )
}

export default TableContainer;