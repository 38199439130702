import { useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';

const TooltipButton = (props) => {
    const tooltipRef = useRef(null);
    const { title, placement, sx, children } = props;

    return (
        <Tooltip title={title} placement={placement ? placement : "bottom"} ref={tooltipRef}>
            {children}
        </Tooltip>
    )
}

export default TooltipButton;